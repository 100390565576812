<template>
  <!-- Leaderboard Section -->
  <section
    class="min-h-screen w-full relative bg-gradient-to-t from-[#1F2846] to-[#536091]"
  >
    <vue-particles
      v-if="isReady"
      class="absolute top-0 left-0 z-10 object-contain h-full min-w-full"
      color="#3E5499"
      :particleOpacity="0.7"
      :particlesNumber="30"
      shapeType="circle"
      :particleSize="11"
      linesColor="#dedede"
      :linesWidth="0"
      :lineLinked="false"
      :moveSpeed="3"
      :hoverEffect="false"
      :clickEffect="false"
      :move="{
        direction: 'bottom',
        outMode: 'out',
        random: true,
        speed: 1,
        straight: true,
      }"
    >
    </vue-particles>

    <div
      class="flex items-center justify-center w-full px-4 pt-[100px] pb-[40px] md:px-0 lg:pt-[80px]"
    >
      <div
        class="w-full flex flex-col justify-center items-center my-8 md:my-10 md:mt-[120px] md:p-4 rounded-3xl z-10"
      >
        <!-- <div class="mt-10 text-center">
            <h1 class="mt-10 text-3xl font-black text-center text-white sm:text-5xl">Coming Soon</h1>
            <p class="mt-4 text-lg text-center text-white">Stay tuned for updates!</p>
            </div> -->
        <img
          v-motion-pop-visible
          src="@/assets/gtb-text.webp"
          class="z-30 object-contain mx-auto animated-image block w-[340px] sm:w-[540px] md:w-[700px] mt-10"
          alt="reward title text"
        />
        <div
          class="w-full max-w-6xl font-montserrat bg-[rgba(255,255,255,0.1)] rounded-xl pr-6 pl-4 lg:px-6 py-4 shadow-lg border-4 border-[#9CA3AF4D] flex items-center justify-between lg:justify-center relative backdrop-filter backdrop-blur-[7px] mt-6 mb-10"
        >
          <button
            @click="goBack"
            class="text-white text-xs sm:text-sm md:text-xl font-bold flex items-center"
          >
            <svg
              class="w-5 h-5 mr-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 19l-7-7 7-7"
              ></path>
            </svg>
            Back
          </button>

          <h2
            class="text-xs sm:text-sm md:text-xl font-bold text-white text-right lg:text-center w-full lg:pr-20"
          >
            Guess The Balance # {{ sessionNumber ? sessionNumber : "" }}
          </h2>
        </div>

        <div
          v-if="sessionDetails && sessionDetails !== 'ongoing' && countdownActive"
          class="flex flex-col justify-center items-center mt-16 mb-10"
        >
          <h2 class="text-3xl font-bold text-white font-montserrat">Session Countdown</h2>
          <div class="relative flex">
            <h4
              class="text-transparent bg-clip-text bg-gradient-to-b from-white to-transparent text-[100px] xl:text-[140px] font-lalezar font-black text-center"
            >
              {{ countdownTime }}
            </h4>
          </div>
        </div>

        <div class="flex flex-col items-center justify-center w-full">
          <div
            class="relative z-30 flex flex-col lg:flex-row items-center justify-center w-full gap-6"
          >
            <!-- GTB Sessions Card -->
            <div
              class="relative small-box b flex flex-col w-[320px] h-[111px] text-center justify-center items-center rounded-[20px] overflow-hidden"
            >
              <img
                src="@/assets/gtb-display-card.webp"
                alt="Card Background"
                class="absolute object-fill w-full h-full"
              />
              <h1
                class="relative font-lalezar text-[#EDB90A] text-[20px] sm:text-[24px] lg:text-[26px]"
              >
                DATE
              </h1>
              <h2
                class="relative font-bold text-white font-lalezar text-[18px] sm:text-[22px] lg:text-[32px] mt-4 lg:mt-0"
              >
                <span v-if="loading || sessionDetails === null" class="loader"></span>
                <span v-else> {{ animatedDate }}</span>
              </h2>
            </div>

            <!-- GTB Spent Card -->
            <div
              class="relative small-box b flex flex-col w-[320px] bg-transparent h-[111px] text-center justify-center items-center overflow-hidden"
            >
              <img
                src="@/assets/gtb-display-card.webp"
                alt="Card Background"
                class="absolute object-fill w-full h-full rounded-[10px]"
              />
              <h1
                class="relative font-lalezar text-[#EDB90A] text-[20px] sm:text-[24px] lg:text-[26px]"
              >
                STARTING BALANCE
              </h1>
              <h2
                class="relative font-bold text-white font-lalezar text-[18px] sm:text-[22px] lg:text-[32px] mt-4 lg:mt-0"
              >
                <span
                  v-if="loading || animatedStartingBalance === null"
                  class="loader"
                ></span>
                <span v-else>${{ animatedStartingBalance }}</span>
              </h2>
            </div>

            <!-- GTB Spent Card -->
            <div
              class="relative small-box b flex flex-col w-[320px] bg-transparent h-[111px] text-center justify-center items-center overflow-hidden"
            >
              <img
                src="@/assets/gtb-display-card.webp"
                alt="Card Background"
                class="absolute object-fill w-full h-full rounded-[10px]"
              />
              <h1
                class="relative font-lalezar text-[#EDB90A] text-[20px] sm:text-[24px] lg:text-[26px]"
              >
                SLOTS COUNT
              </h1>
              <h2
                class="relative font-bold text-white font-lalezar text-[18px] sm:text-[22px] lg:text-[32px] mt-4 lg:mt-0"
              >
                <span v-if="loading || animatedSlotCount === null" class="loader"></span>
                <span v-else>{{ animatedSlotCount }}</span>
              </h2>
            </div>
          </div>
        </div>
        <div
          @click="toggleDisclaimer"
          class="w-full font-montserrat max-w-6xl bg-[rgba(255,255,255,0.1)] rounded-xl lg:px-6 py-4 shadow-lg border-4 border-[#9CA3AF4D] flex items-center justify-center relative backdrop-filter backdrop-blur-[7px] mt-[60px]"
        >
          <button
            class="text-white font-bold flex items-center w-full justify-between px-4"
          >
            <span>Disclaimer</span>
            <span
              class="transition-transform duration-300"
              :class="{ 'rotate-180': showDisclaimer }"
            >
              ▼
            </span>
          </button>
        </div>

        <!-- Disclaimer Dropdown -->
        <transition name="fade">
          <div
            v-if="showDisclaimer"
            class="bg-[rgba(255,255,255,0.1)] shadow-lg border-4 border-[#9CA3AF4D] text-white p-3 sm:p-4 rounded-lg mt-2 max-w-6xl w-full text-sm sm:text-base"
          >
            <p>
              Guess the ending balance after each session. The closest guess to the
              <span class="font-bold text-[#FFD703]">ACTUAL</span> ending balance will be
              declared as the winner.
            </p>
            <p class="mt-3">
              Once the timer expires, the system will
              <span class="font-bold text-[#FFD703]">not accept</span> any more entries.
            </p>
            <p class="mt-3">
              Be careful in entering your guess, you can only enter your guess
              <span class="font-bold text-[#FFD703]">ONCE</span> for every GTB session.
            </p>
          </div>
        </transition>

        <div
          class="hidden lg:flex justify-start w-full max-w-6xl items-center space-x-2 text-white font-bold mt-[60px]"
        >
          <img
            src="../assets/trophy-outline.webp"
            alt="Trophy"
            class="w-5 h-5 object-contain"
          />
          <span>Winner</span>
        </div>

        <div class="hidden lg:flex relative items-center max-w-6xl w-full">
          <!-- Background Image -->
          <img
            src="../assets/gtb-winner-card.webp"
            alt="Prize"
            class="h-full w-full object-fill"
          />

          <!-- Winner Name and Prize -->
          <div
            class="absolute flex justify-between items-center w-full lg:max-w-full pr-[11vw] lg:pr-0 xl:pr-0 xl:max-w-[97%] pt-2 pl-[16vw] xl:pl-[200px]"
          >
            <h1
              class="text-2xl lg:text-4xl xl:text-4xl font-black text-[#FFD703] font-lalezar"
            >
              {{ truncatedUsername || "TBD" }}
            </h1>
            <span
              class="text-sm lg:w-[24vw] lg:max-w-[290px] flex justify-start items-center text-start xl:w-[250px] lg:text-3xl xl:text-4xl font-black font-lalezar text-white rounded-lg shadow"
            >
              Guess: {{ animatedGuess || "TBD" }}
            </span>
            <span
              class="text-sm lg:w-[24vw] lg:max-w-[100px] flex justify-start items-center text-start xl:w-[100px] lg:text-3xl xl:text-4xl font-black font-lalezar text-white rounded-lg shadow"
            >
              Prize:
            </span>
            <span
              class="text-sm lg:w-[24vw] lg:max-w-[290px] flex justify-start items-center text-start xl:w-[250px] lg:text-3xl xl:text-4xl font-black font-lalezar text-white rounded-lg shadow"
            >
              {{ animatedPrize || "TBD" }}
            </span>
          </div>
        </div>

        <div class="flex lg:hidden justify-between items-center w-full mt-16">
          <div
            class="flex justify-start w-full max-w-6xl items-center space-x-2 text-white font-bold"
          >
            <img
              src="../assets/trophy-outline.webp"
              alt="Trophy"
              class="w-5 h-5 object-contain"
            />
            <span>Winner</span>
          </div>
          <h1
            class="text-2xl lg:text-4xl xl:text-5xl font-black text-[#FFD703] font-lalezar"
          >
            {{ animatedUsername }}
          </h1>
        </div>

        <div class="flex lg:hidden justify-between items-center w-full mt-6">
          <h1
            class="text-2xl lg:text-4xl xl:text-5xl font-black text-[#FFD703] font-lalezar"
          >
            Guess
          </h1>
          <span
            class="text-2xl lg:text-3xl xl:text-4xl font-black font-lalezar text-white rounded-lg shadow"
          >
            {{ animatedGuess }}
          </span>
        </div>

        <div class="flex lg:hidden justify-between items-center w-full mt-6">
          <h1
            class="text-2xl lg:text-4xl xl:text-5xl font-black text-[#FFD703] font-lalezar"
          >
            Prize
          </h1>
          <span
            class="text-2xl lg:text-3xl xl:text-4xl font-black font-lalezar text-white rounded-lg shadow"
          >
            {{ animatedPrizeMobile }}
          </span>
        </div>

        <!-- Add Guess Button -->
        <div
          class="flex justify-end items-center w-full max-w-6xl relative mt-10 lg:mt-0"
        >
          <button
            @click="openModal"
            @mouseenter="showTooltip = true"
            @mouseleave="showTooltip = false"
            :disabled="!isEligibleToGuess"
            class="bg-[#232F56] text-white font-medium px-4 py-2 rounded-lg w-60 flex items-center justify-between border-2 border-solid border-gray-500 shadow-md mt-[40px] lg:mt-[100px] relative"
            :class="{
              'opacity-50 cursor-not-allowed': !isEligibleToGuess,
            }"
          >
            Add Guess
            <img
              src="../assets/game.png"
              alt="Game Icon"
              class="w-7 h-5 transition-transform duration-300"
            />
          </button>

          <!-- Tooltip with fade transition -->
          <transition name="fade">
            <div
              v-if="showTooltip && !isEligibleToGuess"
              class="absolute bg-gray-800 text-white text-sm p-2 rounded shadow-lg w-56 bottom-[50px] right-0 mt-2 z-50 text-left"
            >
              <ul class="list-none p-0 m-0 space-y-1">
                <li v-if="!isLoggedIn" class="flex items-center">
                  <span class="text-red-500">❌</span> You need to log in first.
                </li>
                <li v-if="hasGuessed" class="flex items-center">
                  <span class="text-red-500">❌</span> You have already guessed.
                </li>
                <li v-if="sessionDetails?.status === 'done'" class="flex items-center">
                  <span class="text-red-500">❌</span> This GTB session has ended.
                </li>
                <li v-if="sessionDetails?.status === 'ongoing'" class="flex items-center">
                  <span class="text-red-500">❌</span> Guessing has closed.
                </li>
                <li v-if="isLoggedIn && !kick" class="flex items-center">
                  <span class="text-red-500">❌</span> Kick is required.
                </li>
                <li v-if="isLoggedIn && !stake" class="flex items-center">
                  <span class="text-red-500">❌</span> Stake is required.
                </li>
              </ul>
            </div>
          </transition>
        </div>

        <!-- Leaderboard Section -->
        <section class="w-full pb-[40px] md:px-0">
          <div class="w-full flex flex-col items-center justify-center z-10 mt-6">
            <div
              class="w-full max-w-6xl bg-[rgba(255,255,255,0.05)] rounded-3xl p-4 shadow-lg border-4 border-solid border-gray-500 backdrop-filter backdrop-blur-[7px]"
            >
              <div v-if="loading" class="flex justify-center items-center">
                <span class="loader"></span>
              </div>

              <div v-else class="overflow-x-auto">
                <table
                  class="w-full min-w-[900px] text-[#9CA3AF] font-bold border-none border-spacing-y-2"
                >
                  <thead>
                    <tr class="text-left border-0 text-white">
                      <th class="p-3 rounded-l-lg">Slot Name</th>
                      <th class="p-3">Status</th>
                      <th class="p-3">Bonus Buy Cost</th>
                      <th class="p-3">Payout</th>
                      <th class="p-3">Profit/Loss</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="slot in slotData"
                      :key="slot.id"
                      class="bg-[#70416F] text-start rounded-xl"
                    >
                      <td class="p-3">{{ slot.slotTitle }}</td>
                      <td class="p-3 capitalize">{{ slot.status }}</td>
                      <td class="p-3">${{ slot.bonusBuyCost }}</td>
                      <td class="p-3">${{ slot.bonusBuyPayout }}</td>
                      <td
                        class="p-3"
                        :class="slot.profit >= 0 ? 'text-green-500' : 'text-red-500'"
                      >
                        ${{ Math.abs(slot.profit) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
        <!-- Pagination -->
        <div
          class="w-full max-w-6xl mt-10 border-4 border-solid border-gray-500 rounded-lg"
        >
          <div
            v-motion-pop-visible
            class="bg-[rgba(255,255,255,0.1)] rounded-lg p-4 flex items-center justify-between select-none"
          >
            <!-- Left Section: Page Info -->
            <div class="flex items-center gap-2">
              <span class="text-white font-bold text-base sm:text-lg font-montserrat">
                {{ startItem }} - {{ endItem }} of {{ totalItems }} items
              </span>
            </div>

            <!-- Pagination Controls -->
            <div class="flex items-center gap-2">
              <!-- Previous Button -->
              <button
                class="p-2 bg-[#423E81] rounded-md disabled:opacity-50"
                :disabled="currentPage === 1"
                @click="prevPage"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-5 h-5 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="white"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </button>

              <!-- Next Button -->
              <button
                class="p-2 bg-[#423E81] rounded-md disabled:opacity-50"
                :disabled="currentPage === totalPages"
                @click="nextPage"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-5 h-5 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="white"
                  stroke-width="2"
                >
                  <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div class="flex justify-center items-center w-full max-w-6xl">
          <h1 class="font-montserrat text-4xl font-black text-white mb-6 mt-10">
            My Guess
          </h1>
        </div>
        <section class="w-full pb-[40px] md:px-0">
          <div class="w-full flex flex-col items-center justify-center z-10 mt-6">
            <div
              class="w-full max-w-6xl bg-[rgba(255,255,255,0.05)] rounded-3xl p-4 shadow-lg border-4 border-solid border-gray-500 backdrop-filter backdrop-blur-[7px]"
            >
              <div v-if="loading" class="flex justify-center items-center">
                <span class="loader"></span>
              </div>
              <div v-else class="overflow-x-auto">
                <table
                  class="w-full min-w-[900px] text-[#9CA3AF] font-bold border-none border-spacing-y-2"
                >
                  <thead>
                    <tr class="text-left border-0 text-white">
                      <th class="p-3 rounded-l-lg">Username</th>
                      <th class="p-3">Guess</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="guess in filteredGuesses"
                      :key="guess._id"
                      class="bg-[#70416F] text-start rounded-xl"
                    >
                      <td class="p-3">{{ guess.user.username }}</td>
                      <td class="p-3">${{ guess.guess }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <AddGuessModal
      v-if="showModal"
      ref="addGuessModal"
      @guessSubmitted="handleGuessSubmitted"
      @close="closeModal"
    />
    <RedirectModal :showModal="showRedirectModal" @close="showRedirectModal = false" />
  </section>
</template>

<script>
import AddGuessModal from "@/components/AddGuessModal.vue";
import RedirectModal from "@/components/RedirectModal.vue";
import axios from "axios";
import moment from "moment";

export default {
  name: "GuessTheBalanceDetailsPage",
  components: {
    AddGuessModal,
    RedirectModal,
  },
  data() {
    return {
      isReady: false,
      leaderboardData: [],
      slotData: [],
      slotCount: null,
      sessionDetails: null,
      sessionNumber: null,
      userGuesses: [],

      animatedStartingBalance: null,
      animatedSlotCount: null,
      animatedUsername: "",
      animatedGuess: null,
      animatedPrize: null,
      animatedPrizeMobile: null,
      animatedDate: "",
      dateAnimationInterval: null,
      isLoggedIn: false,

      showGuessModal: false,

      loading: false,
      showDisclaimer: false,
      showModal: false,
      showRedirectModal: false,
      showTooltip: false,
      hasGuessed: false,

      countdownTime: "00:00",
      countdownActive: true,

      currentPage: 1,
      itemsPerPage: 10,
    };
  },
  computed: {
    isEligibleToGuess() {
      return (
        this.isLoggedIn &&
        !this.hasGuessed &&
        this.sessionDetails?.status === "started" &&
        this.kick &&
        this.stake
      );
    },
    truncatedUsername() {
      return this.animatedUsername.length > 9
        ? this.animatedUsername.substring(0, 9) + "..."
        : this.animatedUsername;
    },
    totalItems() {
      return this.slotData.length;
    },
    totalPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
    startItem() {
      return (this.currentPage - 1) * this.itemsPerPage + 1;
    },
    endItem() {
      return Math.min(this.startItem + this.itemsPerPage - 1, this.totalItems);
    },
    paginatedSlots() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      return this.slotData.slice(start, start + this.itemsPerPage);
    },
    formattedCountdownTime() {
      if (!this.countdownTime) return "00:00";
      return this.countdownTime;
    },
    filteredGuesses() {
      return this.userGuesses.filter((guess) => guess.gtb._id === this.$route.params.id);
    },
    formattedMinutes() {
      return this.timer ? String(this.timer.minutes.value).padStart(2, "0") : "00";
    },
    formattedSeconds() {
      return this.timer ? String(this.timer.seconds.value).padStart(2, "0") : "00";
    },
  },
  watch: {
    sessionDetails: {
      handler() {
        this.initializeTimer(); // Ensure timer updates when sessionDetails changes
      },
      immediate: true, // Run when component is mounted
    },
  },
  methods: {
    async fetchData() {
      await Promise.all([
        this.fetchGTBSessions(),
        this.fetchSlotStatus(),
        this.fetchUserGuesses(),
        this.checkUserStatus()
      ]);
      
      this.isReady = true;
      
      this.checkLoginStatus();
      this.animateValues();
    },
    async fetchGTBSessions() {
      const baseUrl = process.env.VUE_APP_BASE_API;
      this.loading = true;
      try {
        const response = await axios.get(`${baseUrl}/api/guess-the-balance`, {
          headers: { "x-api-key": process.env.VUE_APP_X_API_KEY },
        });

        if (response.data.status === 200 && Array.isArray(response.data.gtbs)) {
          // Store all GTB sessions
          this.leaderboardData = response.data.gtbs;

          // Find the session by ID
          const sessionId = this.$route.params.id;
          const matchedSession = this.leaderboardData.find(
            (gtb) => gtb._id === sessionId
          );

          if (matchedSession) {
            this.sessionDetails = {
              id: matchedSession._id,
              date: matchedSession.createdAt
                ? new Date(matchedSession.createdAt).toLocaleDateString("en-US")
                : "N/A",
              status: matchedSession.status || "N/A",
              startingBalance: matchedSession.startingBalance ?? 0,
              biggestWin: matchedSession.biggestWin ?? 0,
              endingBalance: matchedSession.endingBalance ?? 0,
              prize: matchedSession.prize ?? 0,
              profitLoss:
                matchedSession.endingBalance !== null &&
                matchedSession.startingBalance !== null
                  ? matchedSession.endingBalance - matchedSession.startingBalance
                  : "TBD",
              category: matchedSession.category || "Uncategorized",
              createdBy: matchedSession.createdBy || "Unknown",
              timerTimeStarted: matchedSession.timerTimeStarted || "N/A",
              timerDuration: matchedSession.timerDuration ?? 0,
              updatedAt: matchedSession.updatedAt || "N/A",
              winner: matchedSession.winner
                ? {
                    id: matchedSession.winner._id || "N/A",
                    guess: matchedSession.winner.guess ?? "N/A",
                    user: matchedSession.winner.user
                      ? {
                          id: matchedSession.winner.user._id || "N/A",
                          username: matchedSession.winner.user.username || "N/A",
                          displayName: matchedSession.winner.user.displayName || "N/A",
                          email: matchedSession.winner.user.email || "N/A",
                          profileImage: matchedSession.winner.user.profileImage || null,
                          discordId: matchedSession.winner.user.discordId || "N/A",
                          status: matchedSession.winner.user.status || "N/A",
                          createdAt: matchedSession.winner.user.createdAt || "N/A",
                          updatedAt: matchedSession.winner.user.updatedAt || "N/A",
                          kick: matchedSession.winner.user.kick || "N/A",
                        }
                      : null,
                  }
                : null,
            };

            // console.log(this.sessionDetails, "status shibal");

            // Find the session position in the sorted list
            const sessionIndex = this.leaderboardData.findIndex(
              (gtb) => gtb._id === sessionId
            );
            this.sessionNumber = sessionIndex !== -1 ? sessionIndex + 1 : null;

            // console.log("Session Number:", this.sessionNumber);
          } else {
            console.log("Session not found");
          }
        }
      } catch (error) {
        console.error("Error fetching GTB sessions", error);
      } finally {
        this.loading = false;
      }
    },
    async fetchSlotStatus() {
      const baseUrl = process.env.VUE_APP_BASE_API;
      const sessionId = this.$route.params.id;
      this.loading = true;

      try {
        const response = await axios.get(`${baseUrl}/api/slot/status/${sessionId}`, {
          headers: { "x-api-key": process.env.VUE_APP_X_API_KEY },
        });

        if (response.data.status === 200 && Array.isArray(response.data.slots)) {
          // Extract required slot details
          this.slotData = response.data.slots.map((slot) => ({
            slotTitle: slot.slotTitle,
            status: slot.status,
            bonusBuyCost: slot.bonusBuyCost,
            bonusBuyPayout: slot.bonusBuyPayout,
            profit: slot.profit,
          }));

          this.slotCount = response.data.gtb?.slotCount || 0;
        } else {
          console.log("No slots found or invalid response");
        }
      } catch (error) {
        console.error("Error fetching slot status", error);
      } finally {
        this.loading = false;
      }
    },
    async fetchUserGuesses() {
      const baseUrl = process.env.VUE_APP_BASE_API;
      const accessToken = localStorage.getItem("accessToken");

      if (!accessToken) {
        console.error("Access token not found");
        return;
      }

      this.loading = true;
      try {
        const response = await axios.get(`${baseUrl}/api/guess-the-balance/all-guesses`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });

        if (response.status === 200 && Array.isArray(response.data.gtbGuesses)) {
          this.userGuesses = response.data.gtbGuesses;
          // console.log("User guesses fetched successfully", this.userGuesses);

          const sessionId = this.$route.params.id;
          this.hasGuessed = this.userGuesses.some((guess) => guess.gtb._id === sessionId);
        }
      } catch (error) {
        console.error("Error fetching user guesses", error);
      } finally {
        this.loading = false;
      }
    },
    async checkUserStatus() {
      try {
        const token = localStorage.getItem("accessToken"); // Retrieve token from localStorage

        if (token) {
          this.$store.commit("UPDATE_USER", { accessToken: token });
        }

        const response = await axios.get(process.env.VUE_APP_BASE_API + "/api/user", {
          headers: {
            "x-api-key": process.env.VUE_APP_X_API_KEY,
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data) {
          this.stake = response.data.user.stake;
          this.kick = response.data.user.kick;

          if (!this.stake || !this.kick) {
            this.showRedirectModal = true;
          }
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    },
    initializeTimer() {
      if (!this.sessionDetails || !this.sessionDetails.timerTimeStarted) return;

      // Get the end time based on the session start time and duration
      const endTime = moment(this.sessionDetails.timerTimeStarted)
        .utc()
        .add(this.sessionDetails.timerDuration, "minutes")
        .valueOf(); // Convert to timestamp

      this.updateCountdown(endTime); // Initialize countdown

      // Clear any existing interval before setting a new one
      if (this.countdownInterval) {
        clearInterval(this.countdownInterval);
      }

      // Set an interval to update countdown every second
      this.countdownInterval = setInterval(() => {
        this.updateCountdown(endTime);
      }, 1000);
    },
    updateCountdown(endTime) {
      const now = moment().utc().valueOf();
      const remainingTime = endTime - now;

      if (remainingTime <= 0) {
        clearInterval(this.countdownInterval);
        this.countdownTime = "00:00";
        this.countdownActive = false; // Hide the countdown
      } else {
        const minutes = Math.floor(remainingTime / 60000);
        const seconds = Math.floor((remainingTime % 60000) / 1000);
        this.countdownTime = `${String(minutes).padStart(2, "0")}:${String(
          seconds
        ).padStart(2, "0")}`;
      }
    },
    refreshData() {
      this.fetchGTBSessions();
    },
    goBack() {
      this.$router.back();
    },
    animateValues() {
      this.animateNumber("animatedStartingBalance", this.sessionDetails.startingBalance);
      this.animateNumber("animatedGuess", this.sessionDetails?.winner?.guess ?? "");
      this.animateNumber("animatedPrize", this.sessionDetails?.prize ?? "");
      this.animateNumber("animatedPrizeMobile", this.sessionDetails?.prize ?? "");
      this.animateNumber("animatedSlotCount", this.slotCount ?? "");
      this.animateName("animatedUsername", this.sessionDetails?.winner?.user?.username);
      this.animateDate("animatedDate", this.sessionDetails.date);
    },
    animateNumber(variable, targetValue) {
      let startValue = this[variable] || 0;
      if (targetValue === startValue) return;

      const increment = Math.max(1, Math.ceil(targetValue / 30));
      const interval = setInterval(() => {
        startValue += increment;
        if (startValue >= targetValue) {
          this[variable] =
            variable === "animatedGuess" || variable === "animatedPrizeMobile"
              ? `$${targetValue.toLocaleString()}`
              : targetValue.toLocaleString();
          clearInterval(interval);
        } else {
          this[variable] = startValue.toLocaleString();
        }
      }, 50);
    },

    animateDate() {
      if (!this.sessionDetails || !this.sessionDetails.date) return;

      const currentYear = new Date().getFullYear(); // Get real-time year
      let currentDate = new Date(currentYear, 0, 1); // January 1st of the current year
      const targetDate = new Date(this.sessionDetails.date);

      this.dateAnimationInterval = setInterval(() => {
        if (currentDate >= targetDate) {
          clearInterval(this.dateAnimationInterval);
          this.animatedDate = targetDate.toLocaleDateString("en-US");
        } else {
          currentDate.setDate(currentDate.getDate() + 2); // Increment 5 days per step
          this.animatedDate = currentDate.toLocaleDateString("en-US");
        }
      }, 30); // Fast animation (updates every 30ms)
    },

    animateName() {
      if (!this.sessionDetails?.winner?.user?.username) return; // Ensure there's a valid username

      let counter = 0;
      const realUsername = this.sessionDetails.winner.user.username;
      const usernameLength = realUsername.length;
      const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      const generateRandomString = (length) => {
        return Array.from(
          { length },
          () => characters[Math.floor(Math.random() * characters.length)]
        ).join("");
      };

      this.usernameAnimationInterval = setInterval(() => {
        if (counter >= 10) {
          // Stop after 1 second (10 * 100ms)
          clearInterval(this.usernameAnimationInterval);
          this.animatedUsername = realUsername;
        } else {
          this.animatedUsername = generateRandomString(usernameLength);
          counter++;
        }
      }, 100);
    },

    getRandomDate() {
      const year = 2024; // Fixed year for consistency
      const month = Math.floor(Math.random() * 12) + 1;
      const day = Math.floor(Math.random() * 28) + 1;
      return `${month}/${day}/${year}`;
    },
    checkLoginStatus() {
      this.isLoggedIn = !!localStorage.getItem("accessToken"); // Convert to boolean
    },
    toggleDisclaimer() {
      this.showDisclaimer = !this.showDisclaimer;
    },
    openModal() {
      const sessionId = this.$route.params.id; // Get session ID from URL
      this.showModal = true;
      this.$nextTick(() => {
        this.$refs.addGuessModal.open(sessionId); // Pass the session ID to the modal
      });
    },
    closeModal() {
      this.showModal = false;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    handleGuessSubmitted() {
      window.location.reload(); // Refresh the page after successful guess submission
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-in-out;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter-from {
  opacity: 0;
}
.fade-enter-to {
  opacity: 1;
}
.fade-leave-from {
  opacity: 1;
}
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-in-out;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}

table {
  border-collapse: separate;
  border-spacing: 0 10px;
}
th,
td {
  padding: 12px;
}

.animated-image {
  animation: growShrink 10s infinite ease-in-out;
}

@keyframes growShrink {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.floating-image1 {
  animation: floatUpDown1 8s infinite ease-in-out, glowEffect1 8s infinite ease-in-out;
}

@keyframes floatUpDown1 {
  0% {
    transform: translateY(20px); /* Start from 20px below */
  }
  50% {
    transform: translateY(0); /* Move to original position */
  }
  100% {
    transform: translateY(20px); /* Move back down */
  }
}

@keyframes glowEffect1 {
  0%,
  100% {
    filter: drop-shadow(0 0 15px rgba(0, 255, 0, 0.5)); /* Glow green at the bottom */
  }
  50% {
    filter: drop-shadow(0 0 30px rgba(0, 255, 0, 0.7)); /* Strong glow when at top */
  }
}

.floating-image2 {
  animation: floatUpDown2 10s infinite ease-in-out, glowEffect2 10s infinite ease-in-out;
}

@keyframes floatUpDown2 {
  0% {
    transform: translateY(20px); /* Start from 20px below */
  }
  50% {
    transform: translateY(0); /* Move to original position */
  }
  100% {
    transform: translateY(20px); /* Move back down */
  }
}

.loader {
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 3px solid white;
  border-radius: 50%;
  border-top-color: transparent;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
