<template>
  <div
    v-if="showModal"
    class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50"
  >
    <div
      class="relative w-[350px] sm:w-[400px] py-6 px-4 rounded-2xl text-start flex flex-col items-center overflow-hidden"
    >
      <img
        src="@/assets/gtb-display-card.webp"
        alt="Card Background"
        class="absolute inset-0 w-full h-full object-cover"
      />

      <!-- Close (X) Button -->
      <button
        @click.stop="closeModal"
        class="absolute top-4 right-6 text-white text-4xl font-bold hover:text-gray-300 transition"
      >
        &times;
      </button>

      <div class="relative z-10 w-full mt-12">
        <!-- LOGIN REQUIRED -->
        <div v-if="!isLoggedIn" class="flex flex-col items-center text-center">
          <h2 class="text-[#EDB90A] text-2xl font-black drop-shadow-md">
            Login Required
          </h2>
          <p class="text-white text-sm px-4 leading-relaxed mt-4">
            You need to be logged in to update your information.
          </p>
          <div class="flex justify-center mt-6 px-4">
            <button
              @click="closeModal"
              class="px-6 py-2 bg-yellow-500 text-black font-semibold rounded-lg shadow-lg hover:bg-yellow-400 transition"
            >
              Close
            </button>
          </div>
        </div>

        <div v-else>
          <!-- Step 1: Guidelines -->
          <div v-if="step === 1" class="flex flex-col">
            <h2 class="text-[#EDB90A] text-2xl font-black drop-shadow-md text-center">
              Verification and Guidelines
            </h2>
            <p class="text-white text-sm px-4 leading-relaxed mt-10">
              To ensure correct tagging and a smooth process, please enter your
              <span class="font-bold text-[#EDB90A]">Stake Username</span> and
              <span class="font-bold text-[#EDB90A]">Kick Username</span> accurately.
            </p>
            <p class="text-white text-sm mt-2 px-4 leading-relaxed">
              These usernames will be linked to your account for verification and
              tracking.
              <span class="font-bold text-[#EDB90A]">Twitter is optional.</span> You can
              skip it if you prefer.
            </p>
            <p class="text-white text-sm mt-2 px-4 leading-relaxed">
              If you're unsure about your usernames, please check your profiles on these
              platforms before continuing.
            </p>
            <div class="flex justify-end mt-10 px-4">
              <button
                @click="step = 2"
                class="px-6 py-2 bg-yellow-500 text-black font-semibold rounded-lg shadow-lg hover:bg-yellow-400 transition"
              >
                Next
              </button>
            </div>
          </div>

          <!-- Step 2: Enter Details -->
          <div v-if="step === 2" class="flex flex-col">
            <h2
              class="text-2xl drop-shadow-md text-[#EDB90A] font-black mb-6 text-center"
            >
              Update Your Details
            </h2>
            <input
              v-model="stakeUsername"
              placeholder="Stake Username"
              class="mt-4 w-full px-4 py-2 rounded-lg bg-white text-black placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-yellow-500"
            />
            <input
              v-model="kickUsername"
              placeholder="Kick Username"
              class="mt-3 w-full px-4 py-2 rounded-lg bg-white text-black placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-yellow-500"
            />
            <input
              v-model="twitterUsername"
              placeholder="Twitter Username (Optional)"
              class="mt-3 w-full px-4 py-2 rounded-lg bg-white text-black placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-yellow-500"
            />

            <p v-if="errorMessage" class="text-red-400 text-center mt-4 font-bold">
              {{ errorMessage }}
            </p>

            <div class="flex justify-between mt-10 px-4">
              <button
                @click="closeModal"
                class="px-6 py-2 bg-gray-500 text-white font-semibold rounded-lg shadow-lg hover:bg-gray-400 transition"
              >
                Cancel
              </button>
              <button
                @click="step = 3"
                class="px-6 py-2 bg-yellow-500 hover:bg-yellow-400 text-black font-semibold rounded-lg shadow-lg transition"
              >
                Next
              </button>
            </div>
          </div>

          <!-- Step 3: Confirm Details -->
          <div v-if="step === 3 && !successMessage" class="flex flex-col">
            <h2
              class="text-2xl drop-shadow-md text-[#EDB90A] font-black mb-6 text-center"
            >
              Confirm Your Changes
            </h2>

            <div class="text-white text-sm px-4 leading-relaxed flex flex-col gap-2">
              <div class="flex justify-between">
                <strong>Stake Username:</strong>
                <span>{{ stakeUsername || "No changes" }}</span>
              </div>
              <div class="flex justify-between">
                <strong>Kick Username:</strong>
                <span>{{ kickUsername || "No changes" }}</span>
              </div>
              <div class="flex justify-between">
                <strong>Twitter Username:</strong>
                <span>{{ twitterUsername || "No changes" }}</span>
              </div>
            </div>

            <div class="flex justify-between mt-6 px-4">
              <button
                @click="step = 2"
                class="px-6 py-2 bg-gray-500 text-white font-semibold rounded-lg shadow-lg hover:bg-gray-400 transition"
              >
                Edit
              </button>
              <button
                @click="updateUserData"
                class="px-6 py-2 bg-yellow-500 hover:bg-yellow-400 text-black font-semibold rounded-lg shadow-lg transition"
              >
                Confirm & Submit
              </button>
            </div>
          </div>

          <!-- Step 4: Submission Success -->
          <div
            v-if="step === 3 && successMessage"
            class="flex flex-col items-center text-center"
          >
            <h2 class="text-2xl drop-shadow-md text-[#EDB90A] font-black mb-6">
              Success!
            </h2>
            <p class="text-white text-sm px-4 leading-relaxed">
              {{ successMessage }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    showModal: Boolean,
  },
  data() {
    return {
      step: 1,
      stakeUsername: "",
      kickUsername: "",
      twitterUsername: "",
      isLoggedIn: !!localStorage.getItem("accessToken"),
      successMessage: "",
      errorMessage: "",
    };
  },
  watch: {
    showModal(newVal) {
      if (newVal && this.isLoggedIn) {
        this.fetchUserData();
      }
    },
  },
  methods: {
    async fetchUserData() {
      try {
        const { data } = await axios.get(`${process.env.VUE_APP_BASE_API}/api/user`, {
          headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        });

        this.stakeUsername = data.stake || "";
        this.kickUsername = data.kick || "";
        this.twitterUsername = data.twitter || "";
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    },

    async updateUserData() {
      this.successMessage = "";
      this.errorMessage = "";

      try {
        await axios.put(
          `${process.env.VUE_APP_BASE_API}/api/user`,
          {
            stake: this.stakeUsername,
            kick: this.kickUsername,
            twitter: this.twitterUsername,
          },
          { headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` } }
        );

        this.successMessage = "Your details have been updated successfully!";

        // Wait 3 seconds before closing the modal
        setTimeout(() => {
          this.closeModal(); // Close modal
          this.$emit("refreshUserData"); // Emit event to refetch user data
        }, 3000);
      } catch (error) {
        this.errorMessage = "Something went wrong. Please try again.";
      }
    },

    closeModal() {
      this.$emit("close");
      this.step = 1;
    },
  },
};
</script>
