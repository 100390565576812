<template>
  <div class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50">
    <div
      class="relative w-[350px] sm:w-[400px] py-6 px-4 rounded-2xl text-start flex flex-col items-center overflow-hidden"
    >
      <img
        src="@/assets/gtb-display-card.webp"
        alt="Card Background"
        class="absolute inset-0 w-full h-full object-cover"
      />

      <div class="relative z-10 w-full">
        <h2 class="text-[#EDB90A] text-2xl font-black drop-shadow-md text-center">
          {{
            success
              ? "Guess Submitted!"
              : errorMessage && !inputError
              ? "Submission Failed"
              : "Enter Your Guess"
          }}
        </h2>

        <!-- Success Animation -->
        <div v-if="success" class="flex flex-col items-center mt-6">
          <p class="text-white text-lg font-semibold mt-4">
            Your guess was submitted successfully!
          </p>
        </div>

        <!-- Error Animation (For API errors) -->
        <div
          v-else-if="errorMessage && !inputError"
          class="flex flex-col items-center mt-6"
        >
          <p class="text-red-500 text-lg font-semibold mt-4">
            Sorry, Something Went Wrong &#128546;
          </p>
        </div>

        <!-- Input Field -->
        <div v-else-if="isLoggedIn">
          <p class="text-yellow-400 text-sm font-medium text-center mt-4">
            Only positive numbers are allowed.
          </p>

          <input
            v-model="guess"
            type="number"
            min="0"
            placeholder="Enter your guess"
            class="mt-6 w-full px-4 py-2 rounded-lg bg-white text-black placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-yellow-500"
            @input="validateGuess"
          />

          <!-- Error message appears below the input field -->
          <p v-if="inputError" class="text-red-500 text-sm mt-2 text-right w-full px-2">
            {{ inputError }}
          </p>

          <div class="flex justify-between mt-6 px-4 w-full">
            <button
              @click="closeModal"
              class="px-6 py-2 bg-gray-500 text-white font-semibold rounded-lg shadow-lg hover:bg-gray-400 transition"
            >
              Cancel
            </button>
            <button
              @click="submitGuess"
              :disabled="loading"
              class="px-6 py-2 bg-yellow-500 hover:bg-yellow-400 text-black font-semibold rounded-lg shadow-lg transition disabled:bg-gray-400"
            >
              {{ loading ? "Submitting..." : "Submit" }}
            </button>
          </div>
        </div>

        <!-- If user is NOT logged in -->
        <div v-else class="text-center mt-6">
          <p class="text-white text-lg font-semibold">
            You need to login to participate in Guess The Balance.
          </p>

          <div class="flex justify-between mt-6 px-4 w-full">
            <button
              @click="closeModal"
              class="px-6 py-2 bg-gray-500 text-white font-semibold rounded-lg shadow-lg hover:bg-gray-400 transition"
            >
              Close
            </button>
            <button
              @click="handleLogin"
              class="px-6 py-2 bg-blue-500 hover:bg-blue-400 text-white font-semibold rounded-lg shadow-lg transition"
            >
              Login
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      guess: "",
      sessionId: null,
      loading: false,
      success: false,
      errorMessage: "",
      inputError: "", // NEW: To show input error below input field
    };
  },
  computed: {
    isLoggedIn() {
      return !!localStorage.getItem("accessToken");
    },
  },
  methods: {
    async submitGuess() {
      const trimmedGuess = this.guess.toString().trim(); // Trim whitespace

      if (!trimmedGuess) {
        this.inputError = "Please enter a valid guess."; // Show error below input
        return;
      }

      this.loading = true;
      this.inputError = ""; // Clear input error
      this.errorMessage = "";

      try {
        const token = localStorage.getItem("accessToken");
        if (!token) {
          this.showError("You need to log in first.");
          return;
        }

        if (!this.sessionId) {
          this.showError("Session ID missing.");
          return;
        }

        console.log("Submitting guess:", this.guess, "Session ID:", this.sessionId);

        const response = await axios.post(
          `${process.env.VUE_APP_BASE_API}/api/guess-the-balance/guess`,
          {
            gtbId: this.sessionId,
            guess: parseInt(trimmedGuess, 10),
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        console.log("API Response:", response.data);
        this.success = true;
        this.guess = "";
        this.$emit("guessSubmitted");
        this.autoClose();
      } catch (error) {
        console.error("Error submitting guess:", error.response?.data || error);
        this.showError(error.response?.data?.message || "Failed to submit guess");
      } finally {
        this.loading = false;
      }
    },
    validateGuess() {
      // Ensure the guess is always positive and remove negative values
      if (this.guess < 0) {
        this.guess = ""; // Clear input if negative
        this.inputError = "Negative values are not allowed.";
      } else {
        this.inputError = "";
      }
    },
    showError(message) {
      this.errorMessage = message;
      this.inputError = ""; // Ensure API errors don't show below input field
      this.autoClose();
    },
    autoClose() {
      setTimeout(() => {
        this.closeModal();
      }, 3000);
    },
    closeModal() {
      this.success = false;
      this.errorMessage = "";
      this.inputError = "";
      this.$emit("close");
    },
    open(id) {
      this.sessionId = id;
      this.success = false;
      this.errorMessage = "";
      this.inputError = "";
    },
    handleLogin() {
      const loginUrl = `${process.env.VUE_APP_BASE_API}/api/auth/discord`;
      window.location.href = loginUrl;
    },
  },
};
</script>
