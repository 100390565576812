<template>
  <!-- Leaderboard Section -->
  <section
    class="min-h-screen w-full relative bg-gradient-to-t from-[#1F2846] to-[#536091]"
  >
    <vue-particles
      v-if="isReady"
      class="absolute top-0 left-0 z-10 object-contain h-full min-w-full"
      color="#3E5499"
      :particleOpacity="0.7"
      :particlesNumber="30"
      shapeType="circle"
      :particleSize="11"
      linesColor="#dedede"
      :linesWidth="0"
      :lineLinked="false"
      :moveSpeed="3"
      :hoverEffect="false"
      :clickEffect="false"
      :move="{
        direction: 'bottom',
        outMode: 'out',
        random: true,
        speed: 1,
        straight: true,
      }"
    >
    </vue-particles>

    <div
      class="flex items-center justify-center w-full px-4 pt-[100px] pb-[40px] md:px-0 lg:pt-[80px]"
    >
      <div
        class="w-full flex flex-col justify-center items-center my-8 md:my-10 md:mt-[120px] md:p-4 rounded-3xl z-10"
      >
        <!-- <div class="mt-10 text-center">
          <h1 class="mt-10 text-3xl font-black text-center text-white sm:text-5xl">Coming Soon</h1>
          <p class="mt-4 text-lg text-center text-white">Stay tuned for updates!</p>
          </div> -->
        <img
          v-motion-pop-visible
          src="@/assets/gtb-text.webp"
          class="z-30 object-contain mx-auto animated-image block w-[340px] sm:w-[540px] md:w-[700px] mt-10"
          alt="reward title text"
        />
        <div class="flex flex-col items-center justify-center w-full mt-[120px]">
          <img
            src="@/assets/gtb-refresh-button.webp"
            alt="GTB Refresh Button"
            class="object-contain w-[340px] hover:scale-105 transition-all duration-300 ease-in-out hover:cursor-pointer"
            @click="refreshData"
          />
          <div
            class="relative z-30 flex items-center justify-center w-full mt-10 gap-x-6"
          >
            <!-- GTB Sessions Card -->
            <div
              class="relative small-box b flex flex-col w-[320px] h-[111px] text-center justify-center items-center rounded-[20px] overflow-hidden"
            >
              <img
                src="@/assets/gtb-display-card.webp"
                alt="Card Background"
                class="absolute object-fill w-full h-full"
              />
              <h1
                class="relative font-lalezar text-[#EDB90A] text-[14px] sm:text-[24px] lg:text-[26px]"
              >
                TOTAL GTB SESSIONS
              </h1>
              <h2
                class="relative font-bold text-white font-lalezar text-[18px] sm:text-[22px] lg:text-[32px]"
              >
                <span
                  v-if="loading || animatedGTBSessions === null"
                  class="loader"
                ></span>
                <span v-else>{{ animatedGTBSessions }}</span>
              </h2>
            </div>

            <!-- GTB Spent Card -->
            <div
              class="relative small-box b flex flex-col w-[320px] bg-transparent h-[111px] text-center justify-center items-center overflow-hidden"
            >
              <img
                src="@/assets/gtb-display-card.webp"
                alt="Card Background"
                class="absolute object-fill w-full h-full rounded-[10px]"
              />
              <h1
                class="relative font-lalezar text-[#EDB90A] text-[14px] sm:text-[24px] lg:text-[26px]"
              >
                TOTAL GTB SPENT
              </h1>
              <h2
                class="relative font-bold text-white font-lalezar text-[18px] sm:text-[22px] lg:text-[32px]"
              >
                <span v-if="loading || animatedGTBSpent === null" class="loader"></span>
                <span v-else>${{ animatedGTBSpent }}</span>
              </h2>
            </div>
          </div>
        </div>

        <!-- Leaderboard Section -->
        <section class="w-full pb-[40px] md:px-0 mt-[100px]">
          <div class="w-full flex flex-col items-center justify-center z-10">
            <!-- Filter Dropdown -->
            <div class="flex justify-end w-full max-w-7xl mb-4">
              <div class="relative">
                <div class="relative inline-block">
                  <button
                    @click="toggleDropdown"
                    class="bg-[#232F56] text-white font-medium px-4 py-2 rounded-lg w-60 flex items-center justify-between border-2 border-solid border-gray-500 shadow-md"
                  >
                    {{ selectedCategory || "All" }}
                    <span
                      class="text-gray-400 text-lg transition-transform duration-300"
                      :class="{ 'rotate-180': dropdownOpen }"
                    >
                      ▼
                    </span>
                  </button>
                  <div
                    v-if="dropdownOpen"
                    class="absolute mt-2 z-30 w-60 bg-[#232F56] rounded-lg shadow-lg border-2 border-solid border-gray-500"
                  >
                    <ul class="py-2 text-white text-base">
                      <li
                        @click="selectCategory('')"
                        class="px-4 py-2 hover:bg-[#404D79] cursor-pointer"
                      >
                        All
                      </li>
                      <li
                        @click="selectCategory('GTB Bonus Buy')"
                        class="px-4 py-2 hover:bg-[#404D79] cursor-pointer"
                      >
                        By GTB Bonus Buy
                      </li>
                      <li
                        @click="selectCategory('GTB Spins Hunt')"
                        class="px-4 py-2 hover:bg-[#404D79] cursor-pointer"
                      >
                        By GTB Spins Hunt
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="w-full max-w-7xl bg-[rgba(255,255,255,0.05)] rounded-3xl p-4 shadow-lg border-4 border-solid border-[#9CA3AF4D] backdrop-filter backdrop-blur-[7px]"
            >
              <div v-if="loading" class="flex justify-center items-center">
                <span class="loader"></span>
              </div>

              <div v-else class="overflow-x-auto">
                <table
                  class="w-full min-w-[900px] text-[#9CA3AF] font-bold border-none border-spacing-y-2"
                >
                  <thead>
                    <tr class="text-left border-0 text-white">
                      <th class="p-3 rounded-l-lg">Status</th>
                      <th class="p-3">Date</th>
                      <th class="p-3">Starting Balance</th>
                      <th class="p-3">Biggest Win</th>
                      <th class="p-3">Ending Balance</th>
                      <th class="p-3">Profit/Loss</th>
                      <th class="p-3">Winner</th>
                      <th class="p-3">Prize</th>
                      <th class="p-3 rounded-r-lg">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(entry, index) in filteredLeaderboard"
                      :key="entry.id"
                      class="bg-[#70416F] text-start rounded-xl"
                      :class="{
                        'bg-gradient-to-r from-[#7d2f7c] via-[#aa24a8] to-[#7d2f7c] text-white':
                          currentPage === 1 && index === 0 && entry.status !== 'done', // Highlight only on first page if not 'done'
                        'bg-[#70416F] text-start rounded-xl':
                          !(currentPage === 1 && index === 0) || entry.status === 'done', // Default background if 'done'
                      }"
                    >
                      <td class="p-3">
                        <span
                          class="bg-[#423E81] text-[#B486F9] px-4 py-2 rounded-lg text-sm font-bold capitalize"
                          >{{ entry.status }}</span
                        >
                      </td>
                      <td class="p-3">{{ entry.date }}</td>
                      <td class="p-3">${{ entry.startingBalance }}</td>
                      <td class="p-3">${{  Math.abs( entry.biggestWin) }}</td>
                      <td class="p-3">${{ entry.endingBalance }}</td>
                      <td class="p-3">
                        <span
                          :class="{
                            'text-green-500':
                              entry.status === 'done' &&
                              entry.endingBalance > entry.startingBalance,
                            'text-red-500':
                              entry.status === 'done' &&
                              entry.endingBalance < entry.startingBalance,
                            'text-white':
                              index === 0 &&
                              (entry.status !== 'done' || !entry.endingBalance), // Black TBD in first row
                            'text-[#9CA3AF]':
                              index !== 0 &&
                              (entry.status !== 'done' || !entry.endingBalance), // Gray TBD for other rows
                          }"
                          class="px-4 py-2 rounded-lg"
                        >
                          {{
                            entry.status === "done" && entry.endingBalance
                              ? "$" +
                                Math.abs(entry.endingBalance - entry.startingBalance)
                              : "TBD"
                          }}
                        </span>
                      </td>
                      <td
                        class="p-3"
                        :class="{
                          'text-green-500': entry.winner?.user?.username,
                          'text-white': index === 0 && !entry.winner?.user?.username, // Black TBD in first row
                          'text-[#9CA3AF]': index !== 0 && !entry.winner?.user?.username, // Gray TBD for other rows
                        }"
                      >
                        {{ truncateUsername(entry.winner?.user?.username) }}
                      </td>
                      <td class="p-3 min-w-[100px] w-[100px] text-center">
                        <span
                          class="bg-[#582B58] text-[#9CA3AF] px-4 py-2 rounded-lg block"
                        >
                          ${{ entry.prize }}
                        </span>
                      </td>
                      <td class="p-3">
                        <button
                          @click="$router.push(`/guess-the-balance/${entry.id}`)"
                          class="bg-gradient-to-r from-orange-500 to-yellow-500 text-white px-4 py-2 rounded-lg shadow-md hover:scale-105 transition-all"
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- Pagination -->
            <div
              class="w-full max-w-7xl mt-10 border-4 border-solid border-[#9CA3AF4D] rounded-lg"
            >
              <div
                v-motion-pop-visible
                class="bg-[rgba(255,255,255,0.05)] rounded-lg p-4 flex items-center justify-between select-none"
              >
                <!-- Left Section: Page Info -->
                <div class="flex items-center gap-2">
                  <span class="text-white font-bold text-base sm:text-lg font-montserrat">
                    {{ pageStart }} - {{ pageEnd }} of {{ totalDocs }} items
                  </span>
                </div>

                <!-- Pagination Controls -->
                <div class="flex items-center gap-2">
                  <!-- Previous Button -->
                  <button
                    class="p-2 bg-[#423E81] rounded-md disabled:opacity-50"
                    :disabled="currentPage === 1"
                    @click="prevPage"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-5 h-5 text-white"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="white"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15 19l-7-7 7-7"
                      />
                    </svg>
                  </button>

                  <!-- Next Button -->
                  <button
                    class="p-2 bg-[#423E81] rounded-md disabled:opacity-50"
                    :disabled="currentPage === totalPages"
                    @click="nextPage"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-5 h-5 text-white"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="white"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "GuessTheBalancePage",
  data() {
    return {
      isReady: false,
      totalGTBSessions: 0,
      totalGTBSpent: 0,
      animatedGTBSessions: 0,
      animatedGTBSpent: 0,
      loading: false,
      leaderboardData: [],
      filteredLeaderboard: [],
      selectedCategory: "All",
      dropdownOpen: false,
      currentPage: 1,
      totalPages: 1,
      totalDocs: 0,
      limit: 10, // Items per page
    };
  },
  computed: {
    pageStart() {
      return this.totalDocs === 0 ? 0 : (this.currentPage - 1) * this.limit + 1;
    },
    pageEnd() {
      return Math.min(this.currentPage * this.limit, this.totalDocs);
    },
  },
  methods: {
    async fetchGTBSessions() {
      if (this.loading) return;
      this.loading = true;

      try {
        const baseUrl = process.env.VUE_APP_BASE_API;
        const params = { page: this.currentPage, limit: this.limit };

        if (this.selectedCategory !== "All") {
          params.category = this.selectedCategory;
        }

        const response = await axios.get(`${baseUrl}/api/guess-the-balance`, {
          params,
          headers: { "x-api-key": process.env.VUE_APP_X_API_KEY },
        });

        // console.log("GTB API Response:", response.data);

        if (response.data.status === 200 && Array.isArray(response.data.gtbs)) {
          this.leaderboardData = []; // Reset leaderboard before updating
          this.processGTBData(response.data.gtbs);
          this.totalPages = response.data.totalPages || 1;
          this.totalDocs = response.data.totalDocs || 0;
        } else {
          console.warn("Unexpected API response format:", response.data);
        }

        // Ensure totalGTBSessions and totalGTBSpent are updated correctly
        if (response.data.gtbSummary) {
          this.totalGTBSessions = response.data.gtbSummary.totalGtbSessions || 0;
          this.totalGTBSpent = response.data.gtbSummary.totalGtbSpend || 0;

          // Animate values after setting them
          this.animateValues();
        }
      } catch (error) {
        console.error("Error fetching GTB sessions:", error);
      } finally {
        this.loading = false;
      }
    },
    processGTBData(gtbs) {
      this.leaderboardData = gtbs.map((gtb) => ({
        id: gtb._id,
        date: new Date(gtb.createdAt).toLocaleDateString("en-US"),
        createdAt: new Date(gtb.createdAt),
        status: gtb.status || "N/A",
        startingBalance: gtb.startingBalance || 0,
        biggestWin: gtb.biggestWin || 0,
        endingBalance: gtb.endingBalance || 0,
        profitLoss:
          gtb.endingBalance !== null && gtb.startingBalance !== null
            ? gtb.endingBalance - gtb.startingBalance
            : "TBD",
        winner: gtb.winner || "N/A",
        category: gtb.category || "Uncategorized",
        prize: gtb.prize,
      }));

      this.filteredLeaderboard = [...this.leaderboardData];

      this.animateValues();
    },
    truncateUsername(username) {
      return username
        ? username.length > 10
          ? username.substring(0, 7) + "..."
          : username
        : "TBD";
    },
    animateValues() {
      this.animateNumber("animatedGTBSessions", this.totalGTBSessions);
      this.animateNumber("animatedGTBSpent", this.totalGTBSpent);
    },
    animateNumber(variable, targetValue) {
      let startValue = parseInt(this[variable]) || 0;
      if (targetValue === startValue) return;

      const increment = Math.max(1, Math.ceil(targetValue / 30));
      const interval = setInterval(() => {
        startValue += increment;
        if (startValue >= targetValue) {
          this[variable] = targetValue.toLocaleString();
          clearInterval(interval);
        } else {
          this[variable] = startValue.toLocaleString();
        }
      }, 50);
    },
    refreshData() {
      this.fetchGTBSessions();
    },
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    selectCategory(category) {
      this.selectedCategory =
        category === "GTB Bonus Buy" || category === "GTB Spins Hunt" ? category : "All";
      this.dropdownOpen = false;
      this.fetchGTBSessions();
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.fetchGTBSessions();
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchGTBSessions();
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.isReady = true;
      this.fetchGTBSessions();
    }, 1000);
  },
};
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0 10px;
}
th,
td {
  padding: 12px;
}

.animated-image {
  animation: growShrink 10s infinite ease-in-out;
}

@keyframes growShrink {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.floating-image1 {
  animation: floatUpDown1 8s infinite ease-in-out, glowEffect1 8s infinite ease-in-out;
}

@keyframes floatUpDown1 {
  0% {
    transform: translateY(20px); /* Start from 20px below */
  }
  50% {
    transform: translateY(0); /* Move to original position */
  }
  100% {
    transform: translateY(20px); /* Move back down */
  }
}

@keyframes glowEffect1 {
  0%,
  100% {
    filter: drop-shadow(0 0 15px rgba(0, 255, 0, 0.5)); /* Glow green at the bottom */
  }
  50% {
    filter: drop-shadow(0 0 30px rgba(0, 255, 0, 0.7)); /* Strong glow when at top */
  }
}

.floating-image2 {
  animation: floatUpDown2 10s infinite ease-in-out, glowEffect2 10s infinite ease-in-out;
}

@keyframes floatUpDown2 {
  0% {
    transform: translateY(20px); /* Start from 20px below */
  }
  50% {
    transform: translateY(0); /* Move to original position */
  }
  100% {
    transform: translateY(20px); /* Move back down */
  }
}

.loader {
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 3px solid white;
  border-radius: 50%;
  border-top-color: transparent;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
