<template>
  <!-- Leaderboard Section -->
  <section
    class="min-h-screen h-full overflow-hidden relative bg-gradient-to-t from-[#1F2846] to-[#536091]"
  >
    <vue-particles
      v-if="particlesVisible"
      key="particles-key"
      class="absolute top-0 left-0 z-10 object-contain w-full h-full"
      color="#3E5499"
      :particleOpacity="0.7"
      :particlesNumber="30"
      shapeType="circle"
      :particleSize="11"
      linesColor="#dedede"
      :linesWidth="0"
      :lineLinked="false"
      :moveSpeed="3"
      :hoverEffect="false"
      :clickEffect="false"
    />

    <div
      class="flex items-center justify-center w-full px-4 pt-[100px] pb-[40px] md:px-0 lg:pt-[80px]"
    >
      <div
        class="w-full flex flex-col justify-center items-center my-8 md:my-10 md:mt-[120px] md:p-4 rounded-3xl z-10"
      >
        <img
          v-motion-pop-visible
          src="@/assets/reward-text.webp"
          class="z-20 object-contain mx-auto animated-image block w-[540px] md:w-[700px] mt-10"
          alt="reward title text"
        />
        <div class="flex items-center justify-center w-full mt-[120px]">
          <img
            v-motion-pop-visible
            src="@/assets/leaderboard-overlay5.webp"
            alt="Leaderboard Overlay3"
            class="object-contain w-[40px] floating-image2"
          />
        </div>
        <div
          class="flex flex-wrap gap-6 mx-auto justify-center items-center w-full lg:w-[800px] mt-[100px]"
        >
          <img
            v-motion-pop-visible
            src="@/assets/reward-card1.webp"
            alt="Reward Card 1"
            class="object-contain w-[200px]"
          />
          <img
            v-motion-pop-visible
            src="@/assets/reward-card2.webp"
            alt="Reward Card 2"
            class="object-contain w-[200px]"
          />
          <img
            v-motion-pop-visible
            src="@/assets/reward-card3.webp"
            alt="Reward Card 3"
            class="object-contain w-[200px]"
          />
          <img
            v-motion-pop-visible
            src="@/assets/reward-card4.webp"
            alt="Reward Card 4"
            class="object-contain w-[200px]"
          />
          <img
            v-motion-pop-visible
            src="@/assets/reward-card5.webp"
            alt="Reward Card 5"
            class="object-contain w-[200px]"
          />
          <img
            v-motion-pop-visible
            src="@/assets/reward-card6.webp"
            alt="Reward Card 6"
            class="object-contain w-[200px]"
          />
          <img
            v-motion-pop-visible
            src="@/assets/reward-card7.webp"
            alt="Reward Card 7"
            class="object-contain w-[200px]"
          />
          <img
            v-motion-pop-visible
            src="@/assets/reward-card8.webp"
            alt="Reward Card 8"
            class="object-contain w-[200px]"
          />
          <img
            v-motion-pop-visible
            src="@/assets/reward-card9.webp"
            alt="Reward Card 9"
            class="object-contain w-[200px]"
          />
          <img
            v-motion-pop-visible
            src="@/assets/reward-card10.webp"
            alt="Reward Card 10"
            class="object-contain w-[200px]"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "RewardPage",
  data() {
    return {
      isReady: false,
      particlesVisible: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.isReady = true;
      this.particlesVisible = false;
      this.$nextTick(() => {
        this.particlesVisible = true; // Re-renders particles
      });
    }, 100);
  },
};
</script>

<style scoped>
.animated-image {
  animation: growShrink 10s infinite ease-in-out;
}

@keyframes growShrink {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.floating-image1 {
  animation: floatUpDown1 8s infinite ease-in-out, glowEffect1 8s infinite ease-in-out;
}

@keyframes floatUpDown1 {
  0% {
    transform: translateY(20px); /* Start from 20px below */
  }
  50% {
    transform: translateY(0); /* Move to original position */
  }
  100% {
    transform: translateY(20px); /* Move back down */
  }
}

@keyframes glowEffect1 {
  0%,
  100% {
    filter: drop-shadow(0 0 15px rgba(0, 255, 0, 0.5)); /* Glow green at the bottom */
  }
  50% {
    filter: drop-shadow(0 0 30px rgba(0, 255, 0, 0.7)); /* Strong glow when at top */
  }
}

.floating-image2 {
  animation: floatUpDown2 10s infinite ease-in-out, glowEffect2 10s infinite ease-in-out;
}

@keyframes floatUpDown2 {
  0% {
    transform: translateY(20px); /* Start from 20px below */
  }
  50% {
    transform: translateY(0); /* Move to original position */
  }
  100% {
    transform: translateY(20px); /* Move back down */
  }
}
</style>
