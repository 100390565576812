<template> 
    <div v-if="showModal" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50">
      <div class="relative w-[350px] sm:w-[400px] py-6 px-4 rounded-2xl text-center flex flex-col items-center overflow-hidden">
        <img src="@/assets/gtb-display-card.webp" alt="Card Background" class="absolute inset-0 w-full h-full object-cover">
        
        <!-- Close (X) Button -->
        <button
          @click.stop="closeModal"
          class="absolute top-4 right-6 text-white text-4xl font-bold hover:text-gray-300 transition"
        >
          &times;
        </button>
  
        <div class="relative z-10 w-full mt-10">
          <h2 class="text-[#EDB90A] text-2xl font-black drop-shadow-md">Action Required</h2>
  
          <p class="text-white text-sm mt-4 px-6 leading-relaxed">
            To continue, please add your 
            <span class="font-bold text-[#EDB90A]">Stake Username</span> and 
            <span class="font-bold text-[#EDB90A]">Kick Username</span>.  
            These are required for verification.
          </p>
  
          <p class="text-white text-sm mt-2 px-6 leading-relaxed">
            Click the button below to update your profile now.
          </p>
  
          <div class="mt-6 flex justify-center">
            <button @click="redirectToProfile" 
              class="px-6 py-2 bg-yellow-500 text-black font-semibold rounded-lg shadow-lg hover:bg-yellow-400">
              Go to Profile
            </button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      showModal: Boolean,
    },
    methods: {
      closeModal() {
        this.$emit("close"); // Emit close event to parent
      },
      redirectToProfile() {
        this.closeModal(); // Close modal first
        this.$router.push("/profile"); // Redirect to Profile Page
      },
    },
  };
  </script>
  
  <style scoped>
  .truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  </style>
  