<template>
  <!-- Footer start -->
  <footer class="relative w-full h-full bg-[#111111]">
    <div class="flex flex-col py-10 lg:px-8 xl:px-20 max-w-[1400px] sm:max-w-[600px] md:max-w-[900px] lg:max-w-[1600px] mx-auto">
      <!-- Main Row: Logo, Navigation Links, Social Icons -->
      <div class="flex flex-col items-center justify-between w-full gap-6 lg:flex-row">
        <!-- Logo Section -->
        <div class="flex items-center gap-2">
          <!-- <a @click="showMenu = false; this.isScrolled = false;" href="">
            <img v-motion-pop-visible alt="logo" src="@/assets/nav-logo.png" />
          </a> -->
          <!-- <a href="#home" class="flex items-center justify-start gap-x-2 sm:gap-x-4"><img v-motion-pop-visible src="@/assets/fugroo-logo.png" class="w-auto h-[52px] object-contain" alt="logo"></a> -->
        </div>

        <!-- Navigation Links -->
        <div v-motion-pop-visible
          class="flex justify-center w-full gap-4 text-lg font-extrabold text-white uppercase font-lato">
          <a 
            class="block w-full py-2 font-black text-center text-[#9CA3AF] font-montserrat ">
        GAMBLE AWARE
          </a>
        </div>
      </div>

      <!-- Centered Paragraphs Section -->
      <div class="flex flex-col items-center w-full gap-4 px-4 py-4 mt-4 text-sm font-normal text-center lg:px-0 font-lato">
        <p v-motion-pop-visible class="text-[#9CA3AF]">
          +18 • We do not take responsibility for any losses from gambling in casinos and betting sites which are linked or promoted on our website(s). As a player, you are responsible for your bets.
        </p>
        <!-- social icons -->
        <div v-motion-pop-visible class="flex items-center justify-center pt-6 gap-x-4">
          <a href=" https://kick.com/keithlocks" target="_blank">
              <img src="@/assets/kick-footer.png" class="h-[26px] w-[26px]" />
            </a>
            <a href="https://x.com/keitthlocks" target="_blank">
              <img src="@/assets/twitter-footer.png" class="h-[30px] w-[30px]" />
            </a>
            <a href="https://discord.com/invite/cv2VZBwgUK" target="_blank">
              <img src="@/assets/discord-footer.png" class="h-[34px] w-[34px]" />
            </a>
        </div>
      </div>
    </div>
    <div  class="w-full h-[80px] flex justify-center items-center bg-[#060606] py-4">
      <p v-motion-pop-visible class="text-[#9CA3AF] font-inter font-light">
        2024 Keithlocks - All Rights Reserved
        </p>
    </div>
  </footer>
  <!-- Footer end -->
</template>


<script>
export default {
  name: 'FooterElement',
  props: {
    activeSection: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      navItems: [
        { id: 'home', name: 'Home' },
        { id: 'promotions', name: 'Promotions' },
        { id: 'socials', name: 'Socials' },
        { id: 'leaderboards', name: 'Leaderboards' },
      ]
    };
  },
}
</script>