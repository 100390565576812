import { createRouter, createWebHistory } from 'vue-router'
import MainView from '@/views/MainView.vue'
import ErrorView from '@/views/ErrorView.vue'
import MainPage from '@/pages/MainPage.vue'
import LeaderboardPage from '@/pages/LeaderboardPage.vue'
import RewardPage from '@/pages/RewardPage.vue'
import GuessTheBalancePage from '@/pages/GuessTheBalancePage.vue'
import ProfilePage from '@/pages/ProfilePage.vue'
import GuessTheBalanceDetails from '@/pages/GuessTheBalanceDetails.vue'
import store from '@/store' 

const routes = [
  {
    path: '/',
    component: MainView,
    meta: { alreadyAuth: true },
    children: [
      {
        path: '/',
        alias: '/',
        component: MainPage
      },
      {
        path: '/leaderboard',  // Add the path for the leaderboard
        component: LeaderboardPage, // Link it to the LeaderboardPage component
      },
      {
        path: '/rewards',  // Add the path for the leaderboard
        component: RewardPage, // Link it to the LeaderboardPage component
      },
      {
        path: '/guess-the-balance',  // Add the path for the leaderboard
        component: GuessTheBalancePage, // Link it to the LeaderboardPage component
      },
      {
        path: '/guess-the-balance/:id',  
        component: GuessTheBalanceDetails, 
      },      
      {
        path: '/profile',  // Add the path for the leaderboard
        component: ProfilePage, // Link it to the LeaderboardPage component
      }
    ]
  },

  {
    path: '/:pathMatch(.*)*',
    name: 'ErrorView',
    component: ErrorView,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,

  // eslint-disable-next-line
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  },
})

// Global navigation guard to check auth status
router.beforeEach(async (to, from, next) => {
  await checkAuthStatus();
  next(); // Proceed to the requested route
});

async function checkAuthStatus() {
  const baseUrl = process.env.VUE_APP_BASE_API;
  try {
    const token =
      store.state.user?.accessToken || localStorage.getItem("accessToken");

    if (!token) {
      console.log("No token found, updating user to null.");
      store.commit("UPDATE_USER", null); // Invalidate user if no token
      store.commit("UPDATE_ISVERIFIED", false); // Set isVerified to false
      return;
    }

    const response = await fetch(`${baseUrl}/api/auth/verifyAccessToken`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = await response.json();
    console.log("Auth verification response:", data);

    if (response.ok && data.isValid) {
      console.log("Token is valid, updating Vuex store.");
      store.commit("UPDATE_ISVERIFIED", true);
      store.commit("UPDATE_USER", data.user); // Update user from response
    } else {
      console.log("Token is not valid, invalidating user.");
      store.commit("UPDATE_USER", null); // Invalidate user if token is not valid
      store.commit("UPDATE_ISVERIFIED", false); // Set isVerified to false
    }
  } catch (error) {
    console.error("Auth verification failed:", error);
    store.commit("UPDATE_USER", null); // Invalidate user on error
    store.commit("UPDATE_ISVERIFIED", false); // Set isVerified to false on error
  }
}



export default router
