<template>
  <!-- Leaderboard Section -->
  <section
    class="h-[4800px] sm:h-[5000px] md:h-[5200px] lg:h-[4800px] xl:h-[4700px] w-full overflow-hidden relative bg-gradient-to-t from-[#1F2846] to-[#536091]">

    <vue-particles class="absolute top-0 left-0 z-10 object-contain h-full min-h-screen" color="#3E5499"
      :particleOpacity="0.7" :particlesNumber="30" shapeType="circle" :particleSize="11" linesColor="#dedede"
      :linesWidth="0" :lineLinked="false" :moveSpeed="3" :hoverEffect="false" :clickEffect="false" :move="{
        direction: 'bottom',
        outMode: 'out',
        random: true,
        speed: 1,
        straight: true
      }">
    </vue-particles>

    <img src="@/assets/leaderboard-overlay1.webp" alt="Leaderboard Prize"
      class="absolute w-[600px] h-[600px] md:w-[800px] md:h-[800px] object-contain -left-[160px] sm:-left-[160px]  md:-left-[200px] top-[2500px] lg:top-[1900px] z-10" />
    <img src="@/assets/leaderboard-overlay2.webp" alt="Leaderboard Prize"
      class="absolute w-[600px] h-[600px]  md:w-[800px] md:h-[800px] object-contain -right-[160px] sm:-right-[160px] md:-right-[200px] top-[3100px] lg:top-[2500px] z-10" />

    <div class="flex items-center justify-center w-full px-4 pt-[100px] md:px-0 lg:pt-0">
      <div class="w-full flex flex-col justify-center items-center my-8 md:my-10 md:mt-[120px] md:p-4 rounded-3xl z-10">
        <div class="relative mt-[160px]">
          <img v-motion-pop-visible src="@/assets/leaderboard-prize.webp" alt="Leaderboard Prize"
            class="object-contain w-[600px] mx-auto animated-image" />
          <img v-motion-pop-visible src="@/assets/leaderboard-overlay3.webp" alt="Leaderboard Overlay1"
            class="absolute  -top-[120px] -left-[30px] object-contain w-[80px] md:w-[95px] floating-image1" />
          <img v-motion-pop-visible src="@/assets/leaderboard-overlay4.webp" alt="Leaderboard Overlay2"
            class="absolute -bottom-[160px] -right-[40px] md:-right-[60px] object-contain w-[80px] md:w-[95px] floating-image2" />
        </div>
        <div v-if="loading == true" class="mt-[140px] text-xl font-bold text-center text-white md:text-4xl"
          style="font-family: Secular One, sans-serif">
          Loading Leaderboard...
        </div>
        <div v-if="loading == false" class="flex items-center justify-center w-full mt-[140px]">
          <img v-motion-pop-visible src="@/assets/leaderboard-overlay5.webp" alt="Leaderboard Overlay3"
            class="object-contain w-[40px] floating-image2" />
        </div>
        <div v-if="loading == false" class=" relative w-full lg:mt-[200px]">
          <div
            class="relative flex flex-col gap-y-[20px] md:gap-y-0 md:flex-row justify-center items-center mt-44 md:mt-0 gap-[34px] md:gap-10 xl:gap-12 md:mb-16 lg:mb-0 lg:mt-16">

            <!-- 2nd place -->
            <div
              class="card-container second-place flex flex-col items-center mt-28 md:mt-[800px] h-[400px] w-[280px]  xl:h-[500px] xl:w-[380px] lg:mt-[88px] relative overflow-hidden">
              <img src="@/assets/card2.png" alt="Card Background"
                class="absolute inset-0 z-0 object-contain w-full h-full" />
              <div class="relative z-10 rounded-none md:pb-1">
                <div class="relative flex flex-col items-center justify-center pt-2 lg:px-6 lg:py-4 rounded-3xl ">
                  <div
                    class="relative flex flex-col items-center justify-center w-full h-[520px] lg:h-[524px] xl:h-[670px] xl:gap-y-4 ">
                    <p
                      class="font-black text-transparent bg-clip-text bg-gradient-to-b from-[#CFCFCF] to-[#CFCFCF] py-2 lg:text-[30px] xl:text-[40px] text-xl overflow-hidden max-w-[130px] md:max-w-full text-ellipsis whitespace-nowrap">
                      {{
                        top3.length > 0 ? getMaskedUsername(top3[1]?.user_name) : ""
                      }}
                    </p>
                    <div class="flex flex-col items-center justify-center w-full ">
                      <p class="text-xl font-bold text-black uppercase lg:text-base"
                        style="font-family: Lato, sans-serif">
                        Wagered
                      </p>
                      <p class="text-white text-xl lg:text-[22px] xl:text-[32px] lg:leading-[38px] uppercase mt-2 lg:mt-0 xl:mt-2 font-bold overflow-hidden max-w-[130px] md:max-w-full text-ellipsis whitespace-nowrap"
                        style="font-family: Lato, sans-serif; 
         text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.8), 
                      1px 1px 5px rgba(0, 0, 0, 0.8);">
                        <span class="text-[#FFCC66]">$</span> {{ top3.length > 0 ? top3[1]?.wagered.replace("$", "") :
                          "" }}
                      </p>
                    </div>
                    <div class="mt-[14px] md:mt-0 relative px-[26px] py-1 lg:py-2 mb-2 lg:px-12 xl:px-16 w-full">
                      <p class="text-white font-black text-2xl md:mt-4 lg:mt-0 lg:text-[38px] xl:text-[48px] xl:leading-[58px] text-center"
                        style="font-family: Lato, sans-serif; 
         text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.8), 
                      1px 1px 5px rgba(0, 0, 0, 0.8);">
                        {{ getRewardValue(1) }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- 1st place -->
            <div
              class="card-container first-place md:absolute flex flex-col items-center h-[400px] w-[280px] xl:h-[500px] xl:w-[380px] lg:-mt-40  lg:relative">
              <img src="@/assets/card1.png" alt="Card Background"
                class="absolute inset-0 z-0 object-contain w-full h-full" />
              <div class="p-2.5 pb-[13px] md:pb-1  rounded-none w-full md:w-[200px] lg:w-[280px] xl:w-[325px] ">
                <div class="relative flex flex-col items-center justify-center pt-2 lg:px-6 lg:py-4 rounded-3xl">
                  <div
                    class="relative flex flex-col items-center justify-center w-full h-[510px] md:h-[510px] lg:h-[380px] xl:h-[544px] gap-y-0 xl:gap-y-4 lg:mt-14">
                    <p
                      class="font-black text-transparent bg-clip-text bg-gradient-to-b from-[#CFCFCF] to-[#CFCFCF] py-2 lg:text-[30px] xl:text-[40px] text-xl overflow-hidden max-w-[130px] md:max-w-full text-ellipsis whitespace-nowrap">
                      {{
                        top3.length > 0 ? getMaskedUsername(top3[0]?.user_name) : ""
                      }}
                    </p>
                    <div class="flex flex-col items-center justify-center w-full ">
                      <p class="text-xl font-bold text-black uppercase lg:text-base"
                        style="font-family: Lato, sans-serif">
                        Wagered
                      </p>
                      <p class="text-white text-xl lg:text-[22px] xl:text-[32px] lg:leading-[38px] uppercase mt-2 font-bold overflow-hidden max-w-[130px] md:max-w-full text-ellipsis whitespace-nowrap"
                        style="font-family: Lato, sans-serif; 
         text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.8), 
                      1px 1px 5px rgba(0, 0, 0, 0.8);">
                        <span class="text-[#FFCC66]">$</span> {{ top3.length > 0 ? top3[0]?.wagered.replace("$", "") :
                          "" }}
                      </p>
                    </div>
                    <div class="mt-[14px] md:mt-0 relative px-[26px] py-1 lg:py-2 mb-3 lg:px-12 xl:px-16 w-full">
                      <p class="text-white font-black text-2xl md:mt-4 lg:mt-2 xl:mt-0 lg:text-[38px] xl:text-[48px] xl:leading-[58px] text-center"
                        style="font-family: Lato, sans-serif; 
         text-shadow: 2px 2px 0px rgba(0, 0, 0, 1), 
                      1px 1px 5px rgba(0, 0, 0, 0.4);">
                        {{ getRewardValue(0) }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- 3rd place -->
            <div
              class="card-container third-place flex flex-col items-center h-[400px] w-[280px] xl:h-[500px] xl:w-[380px] md:mt-[800px] lg:mt-[88px]">
              <img src="@/assets/card3.png" alt="Card Background"
                class="absolute inset-0 z-0 object-contain w-full h-full" />
              <div class="p-2.5 pb-[13px] md:pb-1 rounded-none w-[150px] md:w-[200px] lg:w-[280px] xl:w-[325px]">
                <div class="relative flex flex-col items-center justify-center pt-2 lg:px-6 lg:py-4 rounded-3xl">
                  <div
                    class="relative flex flex-col items-center justify-center w-full h-[510px] lg:h-[350px] xl:h-[510px] gap-y-0 xl:gap-y-4 lg:mt-[70px]">
                    <p
                      class="font-black text-transparent bg-clip-text bg-gradient-to-b from-[#CFCFCF] to-[#CFCFCF] py-2 lg:text-[30px] xl:text-[40px] text-xl overflow-hidden max-w-[130px] md:max-w-full text-ellipsis whitespace-nowrap">
                      {{
                        top3.length > 0 ? getMaskedUsername(top3[2]?.user_name) : ""
                      }}
                    </p>
                    <div class="flex flex-col items-center justify-center w-full ">
                      <p class="mt-1 text-xl font-bold text-black uppercase lg:text-base"
                        style="font-family: Lato, sans-serif">
                        Wagered
                      </p>
                      <p class="text-white text-xl lg:text-[22px] xl:text-[32px] lg:leading-[38px] uppercase mt-2 font-bold overflow-hidden max-w-[130px] md:max-w-full text-ellipsis whitespace-nowrap"
                        style="font-family: Lato, sans-serif; 
         text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.8), 
                      1px 1px 5px rgba(0, 0, 0, 0.8);">
                        <span class="text-[#FFCC66]">$</span> {{ top3.length > 0 ? top3[2]?.wagered.replace("$", "") :
                          "" }}
                      </p>
                    </div>
                    <div class="mt-[14px] md:mt-0 relative px-[26px] py-1 lg:py-2 mb-3 lg:px-12 xl:px-16 w-full">
                      <p class="text-white font-black text-2xl md:mt-2 xl:mt-0 lg:text-[38px] xl:text-[48px] xl:leading-[58px] text-center"
                        style="font-family: Lato, sans-serif; 
         text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.8), 
                      1px 1px 5px rgba(0, 0, 0, 0.8);">
                        {{ getRewardValue(2) }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex flex-col items-center justify-center my-10 lg:mt-20 lg:mb-5">
            <div>
              <div class="bg-transparent">
                <div v-if="stakeTimer && stakeTimer != 'Leaderboard ended'"
                  class="flex flex-row w-full gap-x-2 md:gap-x-6 font-lalezar">

                  <div class="flex items-center justify-center">
                    <h4
                      class="text-transparent bg-clip-text bg-gradient-to-b from-white to-transparent text-[40px] md:text-[80px] lg:text-[100px] xl:text-[140px] font-lalezar font-black text-center">
                      {{ stakeTimer.split(' ')[0].substring(0, stakeTimer.split(' ')[0].length - 1) }}
                    </h4>
                    <h6
                      class="text-[40px] md:text-[80px] lg:text-[100px] xl:text-[140px] text-center text-transparent bg-clip-text bg-gradient-to-b from-white to-transparent font-lalezar font-black">
                      D
                    </h6>
                  </div>


                  <div class="relative flex">

                    <h4
                      class="text-transparent bg-clip-text bg-gradient-to-b from-white to-transparent text-[40px] md:text-[80px] lg:text-[100px] xl:text-[140px] font-lalezar font-black  text-center">
                      {{ stakeTimer.split(' ')[1].substring(0, stakeTimer.split(' ')[1].length - 1) }}
                    </h4>
                    <h6
                      class="text-center text-transparent bg-clip-text bg-gradient-to-b font-black from-white to-transparent text-[40px] md:text-[80px] lg:text-[100px] xl:text-[140px] ">
                      H
                    </h6>

                  </div>

                  <div class="relative flex">

                    <h4
                      class="text-transparent bg-clip-text bg-gradient-to-b from-white to-transparent text-[40px] md:text-[80px] lg:text-[100px] xl:text-[140px] font-lalezar font-black text-center">
                      {{ stakeTimer.split(' ')[2].substring(0, stakeTimer.split(' ')[2].length - 1) }}
                    </h4>
                    <h6
                      class="font-bold text-center text-transparent bg-clip-text bg-gradient-to-b from-white text-[40px] md:text-[80px] lg:text-[100px] xl:text-[140px] to-transparent">
                      M
                    </h6>

                  </div>

                  <div class="relative flex">

                    <h4
                      class="text-transparent bg-clip-text bg-gradient-to-b from-white to-transparent text-[40px] md:text-[80px] lg:text-[100px] xl:text-[140px] font-lalezar font-black text-center">
                      {{ stakeTimer.split(' ')[3].substring(0, stakeTimer.split(' ')[3].length - 1) }}
                    </h4>
                    <h6
                      class="text-center text-transparent bg-clip-text bg-gradient-to-b from-white to-transparent text-[40px] md:text-[80px] lg:text-[100px] xl:text-[140px] font-lalezar font-black ">
                      S
                    </h6>
                  </div>
                </div>
                <div v-else class="text-white text-2xl md:text-[36px] md:leading-[48px] font-bold py-[11px]">
                  Leaderboard Ended
                </div>
              </div>
            </div>
            <div class="flex flex-col items-center justify-center w-full mt-10 gap-y-2 ">
              <h1 class="text-sm italic font-black text-white md:text-lg">The leaderboard is updated every 24 hours.
              </h1>
              <div class="relative flex items-center justify-center w-full p-4 bg-center bg-cover rounded-lg"
                style="background-image: url('/path-to-your-background-image.jpg');">
                <div
                  class="flex items-center border-2 border-[#4800FF] justify-center p-2 space-x-2 bg-transparent bg-opacity-75 rounded-md w-full md:w-[600px]">
                  <img src="@/assets/warning-icon.png" alt="Warning" class="w-5 h-5">
                  <p class="text-xs font-black text-center text-[#9CA3AF] md:text-sm md:text-start">
                    Wager abuse will be removed from the leaderboard and have their prize forfeited.
                  </p>
                  <img src="@/assets/warning-icon.png" alt="Warning" class="w-5 h-5">
                </div>
              </div>
              <div class="relative z-30 flex items-center justify-center w-full mt-10 gap-x-6">
                <!-- Active Card -->
                <div
                  class="relative flex flex-col w-[320px] h-[111px] hover:cursor-pointer text-center justify-center items-center rounded-[20px] overflow-hidden"
                  :class="stakePreviousLeaderboard
                    ? ''
                    : 'small-box b'
                    " @click="
                      stakePreviousLeaderboard = false;
                    changeLeaderboard();
                    ">
                  <img src="@/assets/gtb-display-card.webp" alt="Card Background"
                    class="absolute object-fill w-full h-full">
                  <h1 class="relative font-lalezar font-bold text-[22px] sm:text-[28px] lg:text-[32px]" :class="stakePreviousLeaderboard
                    ? 'text-white'
                    : 'text-[#EDB90A]'
                    " style="text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.8), 
                      1px 1px 5px rgba(0, 0, 0, 0.8);">ACTIVE</h1>
                </div>

                <!-- Ended Card -->
                <div
                  class="relative flex flex-col w-[320px] hover:cursor-pointer bg-transparent h-[111px] text-center justify-center items-center overflow-hidden"
                  :class="stakePreviousLeaderboard
                    ? 'small-box b'
                    : 'text-white'
                    " @click="
                      stakePreviousLeaderboard = true;
                    changeLeaderboard();
                    ">
                  <img src="@/assets/gtb-display-card.webp" alt="Card Background"
                    class="absolute object-fill w-full h-full rounded-[10px]">
                  <h1 class="relative font-lalezar font-bold text-[22px] sm:text-[28px] lg:text-[32px]" :class="stakePreviousLeaderboard
                    ? 'text-[#EDB90A]'
                    : 'text-white'
                    " style="text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.8), 
                      1px 1px 5px rgba(0, 0, 0, 0.8);">ENDED</h1>
                </div>
              </div>
            </div>
          </div>

          <section
            class="flex flex-col items-center w-full mt-20 mb-24 mx-auto max-w-[1180px] bg-[rgba(255,255,255,0.05)] border-4 border-solid rounded-2xl border-gray-500 py-4 px-2 md:px-4 backdrop-filter backdrop-blur-[7px] z-30">
            <div class="flex justify-between w-full py-4 text-lg font-bold text-gray-400">
              <div class="w-1/4 pl-4 text-sm text-left lg:text-lg">RANK</div>
              <div class="w-1/4 text-sm text-center lg:text-lg">USER</div>
              <div class="w-1/4 text-sm text-center lg:text-lg">WAGERED</div>
              <div class="w-1/4 pr-4 text-sm text-right lg:text-lg">PRIZE</div>
            </div>

            <div v-for="(item, index) in topRest" :key="index"
              class="flex justify-between w-full py-2 my-2  transform transition-transform bg-[#70416F] rounded-xl hover:scale-[1.02]">
              <div
                class="w-1/4 pl-4 text-xs font-black tracking-wide text-left text-gray-300 uppercase sm:text-sm md:text-lg">
                {{ getOrdinalSuffix(index + 4) }}
              </div>
              <div class="w-1/4 text-xs font-black text-center text-gray-300 truncate sm:text-sm md:text-lg">
                {{ getMaskedUsername(item?.user_name) }}
              </div>
              <div class="w-1/4 text-xs font-black text-center text-gray-300 truncate rounded-lg sm:text-sm md:text-lg">
                $ {{ item?.wagered.replace("$", "") }}
              </div>
              <div class="w-1/4 pr-4 text-xs font-black text-right text-gray-300 sm:text-sm md:text-lg">
                $ {{ getRewardValue(index + 3) }}
              </div>
            </div>
          </section>

        </div>
      </div>
    </div>
  </section>
</template>

<script>
// import axios from 'axios';
import axios from "axios";
// import { STAKE_CURRENT_PRIZES, STAKE_PREVIOUS_PRIZES } from "../../const";
import moment from "moment-timezone";
// import { watch } from 'vue';
// import PrevLeaderboardSection from './PrevLeaderboardSection.vue';

export default {
  name: "LeaderboardSection",
  data() {
    return {
      top3: [],
      topRest: [],
      stakeTimer: "",
      topForMobile: [],
      selectedLeaderboard: false,
      activeTabImage: require("../assets/tab-active.png"),
      inActiveTabImage: require("../assets/tab-inactive.png"),
      leaderboardType: "stake",
      stakeCurrentLeaderboard: [],
      stakePrevLeaderboard: [],
      stakeTimerInterval: null,

      stakePreviousLeaderboard: false,

      startDate: null,
      endDate: null,

      notFormattedEndDate: null,

      loading: false,
    };
  },
  watch: {
    selectedLeaderboard(newSelected) {
      if (newSelected) {
        this.activeTabImage = require("../assets/tab-inactive.png");
        this.inActiveTabImage = require("../assets/tab-active.png");
      } else {
        this.activeTabImage = require("../assets/tab-active.png");
        this.inActiveTabImage = require("../assets/tab-inactive.png");
      }
    },
  },
  methods: {
    async init() {
      const baseUrl = process.env.VUE_APP_BASE_API;
      this.loading = true;

      try {
        const stakeCurrentResponse = await axios.get(
          `${baseUrl}/api/leaderboard/stake`,
          {
            headers: {
              "x-api-key": process.env.VUE_APP_X_API_KEY,
            },
          }
        );

        const stakePrevResponse = await axios.get(
          `${baseUrl}/api/leaderboard/stake?previous=true`,
          {
            headers: {
              "x-api-key": process.env.VUE_APP_X_API_KEY,
            },
          }
        );

        this.stakeCurrentLeaderboard = stakeCurrentResponse.data.leaderboard || [];
        this.stakePrevLeaderboard = stakePrevResponse.data.leaderboard || [];
        this.stakeNotFormattedEndDate = stakeCurrentResponse.data.endDate || null;

      } catch (error) {
        console.error("Error loading leaderboard data", error);
      } finally {
        this.loading = false;
        this.changeLeaderboard();
      }
    },

    changeLeaderboard() {
      if (this.leaderboardType === "stake") {
        console.log(this.stakePrevLeaderboard, "prevs");
        console.log(this.stakeCurrentLeaderboard, "currents");
        console.log(this.stakeTimer);
        if (this.stakePreviousLeaderboard) {
          const leaderboard = this.stakePrevLeaderboard;
          this.top3 = leaderboard.slice(0, 3);
          this.topRest = leaderboard.slice(3, 40);
          this.topForMobile = leaderboard.slice(0, 40);

          clearInterval(this.stakeTimerInterval);
          this.stakeTimer = "Leaderboard ended";
        } else {
          const leaderboard = this.stakeCurrentLeaderboard;
          this.top3 = leaderboard.slice(0, 3);
          this.topRest = leaderboard.slice(3, 40);
          this.topForMobile = leaderboard.slice(0, 40);

          this.startStakeTimer()
        }
      }
    },

    toggleLeaderboard(leaderboardType) {
      this.currentLeaderboard = leaderboardType;
      this.changeLeaderboard();
    },
    getMaskedUsername(username) {
      if (username.length <= 5) {
        return username[0] + "*****"; // Mask all but the first character
      } else if (username.length <= 7) {
        return username.substring(0, 2) + "*****" + username.substring(username.length - 1);
      }
      return (
        username.substring(0, 3) +
        "*****" +
        username.substring(username.length - 2)
      );
    },
    getOrdinalSuffix(n) {
      const s = ["th", "st", "nd", "rd"],
        v = n % 100;
      return n + (s[(v - 20) % 10] || s[v] || s[0]);
    },
    getRewardValue(rank) {
      let reward = "";

      if (this.leaderboardType === "stake") {
        reward = this.getStakeRewardValue(rank); // Get stake reward value for the rank
      }

      return reward;
    },
    getStakeRewardValue(rank) {
      let prizes;
      const stakePreviousLeaderboard = this.stakePreviousLeaderboard;
      if (stakePreviousLeaderboard) {
        prizes = JSON.parse(
          process.env.VUE_APP_STAKE_PREVIOUS_PRIZES
        );
      } else {
        prizes = JSON.parse(
          process.env.VUE_APP_STAKE_CURRENT_PRIZES
        );
      }

      let reward =
        rank >= prizes.length ? prizes[prizes.length - 1] : prizes[rank];
      return reward; // Ensure the reward is returned as a string with a euro sign
    },

    startStakeTimer() {
      if (this.timerInterval) clearInterval(this.timerInterval);

      const getNextTargetDate = () => {
        const now = moment().utc();
        let targetDate = moment(`${this.stakeNotFormattedEndDate}T00:00:00Z`)
          .utc()
          .subtract(1, "seconds");

        if (now.isAfter(targetDate)) {
          targetDate.add(1, "week");
        }

        return targetDate;
      };

      let targetDate = getNextTargetDate();

      const updateTimer = () => {
        const nowEst = moment.tz("UTC");
        const distance = targetDate.diff(nowEst);
        // console.log("distance", distance);
        if (distance >= 0) {
          const duration = moment.duration(distance);
          const days = Math.floor(duration.asDays());
          const hours = duration.hours();
          const minutes = duration.minutes();
          const seconds = duration.seconds();

          this.stakeTimer = `${days}d ${hours}h ${minutes}m ${seconds}s`;
        } else {
          this.stakeTimer = "Leaderboard ended";
          clearInterval(this.stakeTimerInterval);
        }
      };

      updateTimer();
      this.stakeTimerInterval = setInterval(updateTimer, 1000);
    },
    isMobile() {
      return window?.innerWidth < 768; // Adjust based on your 'lg' breakpoint in Tailwind CSS config
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped>
   .animated-image {
    animation: growShrink 10s infinite ease-in-out;
  }

  @keyframes growShrink {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1); 
    }
    100% {
      transform: scale(1); 
    }
  }

  .floating-image1 {
    animation: floatUpDown1 8s infinite ease-in-out, glowEffect1 8s infinite ease-in-out;
  }

  @keyframes floatUpDown1 {
    0% {
      transform: translateY(20px); /* Start from 20px below */
    }
    50% {
      transform: translateY(0); /* Move to original position */
    }
    100% {
      transform: translateY(20px); /* Move back down */
    }
  }

  @keyframes glowEffect1 {
    0%, 100% {
      filter: drop-shadow(0 0 15px rgba(0, 255, 0, 0.5)); /* Glow green at the bottom */
    }
    50% {
      filter: drop-shadow(0 0 30px rgba(0, 255, 0, 0.7)); /* Strong glow when at top */
    }
  }

  .floating-image2 {
    animation: floatUpDown2 10s infinite ease-in-out, glowEffect2 10s infinite ease-in-out;
  }

  @keyframes floatUpDown2 {
    0% {
      transform: translateY(20px); /* Start from 20px below */
    }
    50% {
      transform: translateY(0); /* Move to original position */
    }
    100% {
      transform: translateY(20px); /* Move back down */
    }
  }

@keyframes glowEffect2 {
    0%, 100% {
      filter: drop-shadow(0 0 15px rgba(0, 255, 0, 0.5)); 
    }
    50% {
      filter: drop-shadow(0 0 30px rgba(0, 255, 0, 0.7)); 
    }
  }

  .card-container {
  transform-style: preserve-3d; /* Ensures child elements are rendered in 3D */
  transition: transform 0.5s ease; /* Smooth animation */
}

/* 2nd place card perspective */
.second-place {
  transform: perspective(1000px) rotateX(9deg) rotateY(22deg);
}

.second-place:hover {
  transform: perspective(1000px) rotateX(0deg) rotateY(0deg);
}

/* 1st place card perspective */
.first-place {
  transform: perspective(1000px) rotateX(18deg);
}

.first-place:hover {
  transform: perspective(1000px) rotateX(0deg);
}

/* 3rd place card perspective */
.third-place {
  transform: perspective(1000px) rotateX(9deg) rotateY(-22deg);
}

.third-place:hover {
  transform: perspective(1000px) rotateX(0deg) rotateY(0deg);
}

@media (max-width: 760px) {
  .second-place,
  .first-place,
  .third-place {
    transform: none; /* Remove perspective and rotation */
  }

  .second-place:hover,
  .first-place:hover,
  .third-place:hover {
    transform: none; /* Remove hover effects */
  }
}

  .toggle-switch {
    position: relative;
    display: inline-flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    width: 632px;
    height: 72px;
    border: 2px #2cceee;
    /* background: linear-gradient(to right, #121212, #461B83 , #121212); */
    border-radius: 20px;
    padding: 0 8px;
    cursor: pointer;
    
  
    @media screen and (max-width: 640px) {
      width: 300px;
      height: 74px;
    }
  }
  
  .toggle-switch input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
    
  }
  
  .toggle-switch .slider {
    position: absolute;
    left: 0;
    top: 2px;
    border-radius: 10px;
    width: 300px;
    height: 72px;
    background: url("../assets/tab-active.png") no-repeat;
    background-color: #00C3FF;
    /* border-radius: 24px; */
    transition: transform 0.3s ease;
    box-shadow: -4px -4px 15px 3px rgba(0, 195, 255, 0.5), 4px 4px 15px 3px rgba(0, 178, 209, 0.5);
  }
  
  .toggle-switch input:checked + .slider {
    transform: translateX(330px);
  
    @media screen and (max-width: 640px) {
      transform: translateY(100px);
    }
  }
  
  .toggle-switch span {
    font-size: 35px;
    font-weight: bold;
    z-index: 1;
    padding: 0 8px;
  
  }
  
  .toggle-switch .left-label {
    position: absolute;
    left: 0px;
    margin-top: 5px;
    font-weight: 50;
    
  
    @media screen and (max-width: 640px) {
      top: 0;
      /* left: 100px; */
    }
  }
  
  .toggle-switch .right-label {
    position: absolute;
    right: 2px;
    margin-top: 5px;
    font-weight: 50;
    @media screen and (max-width: 640px) {
      bottom: -80px;
      /* left: 100px; */
    }
  }
  
  .custom-border {
    opacity: 1;
    margin: 10px;
    border: solid 2px transparent;
    border-radius: 10px;
    background-image: linear-gradient(#090B2A, #090B2A), 
                      linear-gradient(to bottom, #ED3C80, #3E6B3E);
    background-origin: border-box;
    background-clip: padding-box, border-box;
    /* background: url('../assets/tab-active.png') no-repeat; rgba(159,84,249,0) */
  }
  </style>
