<template>
  <div class="relative">
    <!-- Navbar -->
    <nav
      class="absolute top-4 left-1/2 transform -translate-x-1/2 flex w-full lg:w-[1040px] justify-center items-center px-10 py-3.5 transition-all duration-300 ease-in-out z-50"
    >
      <div
        class="container relative w-[800px] h-[60px] bg-[url('@/assets/nav-background.png')] bg-cover bg-center rounded-xl px-6 lg:px-10 mx-auto max-w-[1400px] flex justify-between items-center"
      >
        <!-- Two White Boxes -->
        <div class="absolute flex gap-x-20 -top-[30px] inset-x-0 justify-center gap-2">
          <div class="w-[30px] h-[30px] bg-white"></div>
          <div class="w-[30px] h-[30px] bg-white"></div>
        </div>

        <!-- Logo -->
        <div v-motion-pop-visible class="flex items-center justify-start gap-x-2">
          <router-link to="/" class="flex items-center justify-start gap-x-2">
            <img
              src="@/assets/keithlocks-logo.png"
              class="w-auto h-[80px] object-contain"
              alt="logo"
            />
          </router-link>
        </div>

        <!-- Desktop Navigation -->
        <div
          v-motion-pop-visible
          class="flex-col hidden text-lg lg:flex font-lato md:flex-row justify-evenly whitespace-nowrap"
        >
          <router-link
            v-for="item in navItems"
            :key="item.id"
            :to="`/${item.id}`"
            class="block w-full lg:w-auto text-[18px] font-black text-white font-lato px-4 py-2 rounded transition-all duration-300 ease-in-out"
            active-class="font-black text-white"
            exact-active-class="font-black text-[#D291FB]"
          >
            {{ item.name }}
          </router-link>
        </div>

        <!-- Mobile Hamburger Button -->
        <div class="lg:hidden">
          <button @click="toggleSidebar" class="focus:outline-none">
            <svg
              v-if="!isSidebarOpen"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="white"
              class="w-9 h-9"
            >
              <path
                fill-rule="evenodd"
                d="M3 6.75A.75.75 0 0 1 3.75 6h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 6.75ZM3 12a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 12Zm0 5.25a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75Z"
                clip-rule="evenodd"
              />
            </svg>
            <svg
              v-else
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="white"
              class="w-9 h-9"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      </div>
      <!-- Login Button -->
      <button
        v-if="!isUserLoggedIn"
        @click="handleLogin()"
        type="button"
        class="hidden text-white lg:flex w-[100px] h-[60px] text-[18px] font-black font-lato hover:cursor-pointer bg-[url('@/assets/nav-background.png')] bg-cover bg-center rounded-xl px-6 lg:px-10 justify-center items-center transition-all ease-in-out hover:scale-110 duration-200"
      >
        Login
      </button>

      <button
        v-if="isUserLoggedIn"
        @click="handleLogout()"
        type="button"
        class="hidden text-white lg:flex w-[100px] h-[60px] text-[18px] font-black font-lato hover:cursor-pointer bg-[url('@/assets/nav-background.png')] bg-cover bg-center rounded-xl px-6 lg:px-10 justify-center items-center transition-all ease-in-out hover:scale-110 duration-200"
      >
        Logout
      </button>
    </nav>
    <!-- Mobile Sidebar Drawer -->
    <div
      :class="{
        'translate-x-0 w-[100%]': isSidebarOpen,
        '-translate-x-full': !isSidebarOpen,
      }"
      class="fixed top-[0] left-0 h-full bg-opacity-70 z-50 backdrop-blur-lg bg-[#5f65a1] transform transition-transform duration-300 ease-in-out"
    >
      <div class="mt-[85px] text-white font-extrabold font-lato text-lg uppercase">
        <div class="absolute lg:hidden top-8 right-8">
          <button @click="toggleSidebar" class="focus:outline-none">
            <svg
              v-if="!isSidebarOpen"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="white"
              class="w-9 h-9"
            >
              <path
                fill-rule="evenodd"
                d="M3 6.75A.75.75 0 0 1 3.75 6h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 6.75ZM3 12a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 12Zm0 5.25a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75Z"
                clip-rule="evenodd"
              />
            </svg>
            <svg
              v-else
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="white"
              class="w-9 h-9"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <router-link
          v-for="item in navItems"
          :key="item.id"
          :to="`/${item.id}`"
          @click="closeSidebar"
          class="block w-full px-6 py-4 mb-1 text-left text-white transition duration-200"
          active-class="font-extrabold text-white"
          exact-active-class="font-extrabold text-[#D291FB]"
        >
          {{ item.name }}
        </router-link>
        <!-- Login Button -->
        <button
          v-if="!isUserLoggedIn"
          @click="handleLogin()"
          type="button"
          class="block w-full px-6 py-4 mb-1 font-extrabold text-left text-white"
        >
          LOGIN
        </button>
        <button
          v-if="isUserLoggedIn"
          @click="handleLogout()"
          type="button"
          class="block w-full px-6 py-4 mb-1 font-extrabold text-left text-white"
        >
          LOGOUT
        </button>
      </div>
    </div>

    <!-- Sidebar Overlay -->
    <div
      v-if="isSidebarOpen"
      @click="closeSidebar"
      class="fixed inset-0 z-20 transition-opacity duration-300 ease-in-out bg-opacity-75 bg-gray-950"
    ></div>
  </div>
</template>

<script>
export default {
  name: "NavbarElement",
  props: {
    activeSection: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isScrolled: false,
      isSidebarOpen: false,
      navItems: [
        { id: "", name: "Home" },
        { id: "leaderboard", name: "Leaderboards" },
        { id: "rewards", name: "Rewards" },
        { id: "guess-the-balance", name: "Guess The Balance" },
        { id: "profile", name: "Profile" },
      ],
    };
  },
  computed: {
    isUserLoggedIn() {
        console.log( this.$store.state.isVerified)
    return this.$store.state.user !== null && this.$store.state.isVerified; 
   
  },
  },
  methods: {
    async checkAuthStatus() {
    const baseUrl = process.env.VUE_APP_BASE_API;
    try {
      const token =
        this.$store.state.user?.accessToken || localStorage.getItem("accessToken");

      if (!token) {
        console.log("No token found, updating user to null.");
        return this.$store.commit("UPDATE_USER", null); // If no token, invalidate user
      }

      const response = await fetch(`${baseUrl}/api/auth/verifyAccessToken`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      console.log("Auth verification response:", data);

      if (response.ok && data.isValid) {
        console.log("Token is valid, updating Vuex store.");
        this.$store.commit("UPDATE_ISVERIFIED", true);
        this.$store.commit("UPDATE_USER", data.user);
      } else {
        console.log("Token is not valid, invalidating user.");
        this.$store.commit("UPDATE_USER", null);
        this.$store.commit("UPDATE_ISVERIFIED", false);
      }
    } catch (error) {
      console.error("Auth verification failed:", error);
      this.$store.commit("UPDATE_USER", null);
      this.$store.commit("UPDATE_ISVERIFIED", false);
    }
  },
    handleLogin() {
      const loginUrl = `${process.env.VUE_APP_BASE_API}/api/auth/discord`;
      window.location.href = loginUrl;

      //   console.log(store.state.user)
    },
    handleLogout() {
      // Clear Vuex state
      this.$store.commit("UPDATE_USER", null);

      // Remove local storage items
      localStorage.removeItem("vuex");
      localStorage.removeItem("accessToken");
      sessionStorage.clear();

      // Redirect to login/home page
      this.$router.replace("/");

      // Close sidebar if open
      this.isSidebarOpen = false;

      this.checkAuthStatus();
    },
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;

      if (this.isSidebarOpen && window.innerWidth < 768) {
        this.isScrolled = true;
      } else if (!this.isSidebarOpen && window.scrollY === 0) {
        this.isScrolled = false;
      }
    },
    closeSidebar() {
      this.isSidebarOpen = false;
    },
    handleScroll() {
      this.isScrolled = window.scrollY > 0;

      if (this.isScrolled && window.innerWidth < 768) {
        this.isScrolled = true;
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },

  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
