<template>
  <!-- Leaderboard Section -->
  <section
    class="min-h-screen w-full relative bg-gradient-to-t from-[#1F2846] to-[#536091]"
  >
    <vue-particles
      class="absolute top-0 left-0 z-10 object-contain h-full min-w-full"
      color="#3E5499"
      :particleOpacity="0.7"
      :particlesNumber="30"
      shapeType="circle"
      :particleSize="11"
      linesColor="#dedede"
      :linesWidth="0"
      :lineLinked="false"
      :moveSpeed="3"
      :hoverEffect="false"
      :clickEffect="false"
      :move="{
        direction: 'bottom',
        outMode: 'out',
        random: true,
        speed: 1,
        straight: true,
      }"
    >
    </vue-particles>

    <div
      class="flex items-center justify-center w-full px-4 pt-[100px] pb-[40px] md:px-0 lg:pt-[80px]"
    >
      <!-- <div class="flex items-center justify-center w-full px-4 pt-[100px] pb-[40px] md:px-0 lg:pt-[80px]">
        <div
          class="w-full flex flex-col justify-center items-center my-8 md:my-10 md:mt-[60px] md:p-4 rounded-3xl z-10"> -->
      <!-- Coming Soon Text -->
      <!-- <h1 class="mt-10 text-3xl font-black text-center text-white sm:text-5xl">Coming Soon</h1>
          <p class="mt-4 text-lg text-center text-white">Stay tuned for updates!</p>
        </div>
      </div> -->
      <div
        class="w-full flex flex-col justify-center items-center my-8 md:my-10 md:mt-[60px] md:p-4 rounded-3xl z-10"
      >
        <img
          v-motion-pop-visible
          src="@/assets/profile-text.webp"
          class="z-30 object-contain mx-auto animated-image block w-[340px] sm:w-[540px] md:w-[500px] mt-10"
          alt="reward title text"
        />
        <div class="flex flex-col items-center justify-center w-full">
          <div
            class="relative z-30 flex flex-col items-center justify-center w-full mt-10 gap-x-6"
          >
            <!-- Profile Card -->
            <div
              class="relative profile-box b flex flex-col gap-y-6 sm:gap-y-0 w-[380px] h-[380px] sm:w-[600px] md:w-[670px] sm:h-[430px] p-4 sm:p-10 text-center justify-center items-center overflow-hidden"
            >
              <img
                src="@/assets/profile-display-card.webp"
                alt="Card Background"
                class="absolute object-fill w-full h-full"
              />
              <div
                class="z-20 flex items-center gap-4 border border-[#FECB66] p-6 rounded-[20px] w-full"
              >
                <div
                  class="flex items-center justify-center w-[100px] h-[100px] sm:w-[140px] sm:h-[140px] bg-gray-700 border-2 border-purple-400 rounded-full"
                >
                  <img
                    :src="picUrl || require('@/assets/profile-placeholder.webp')"
                    alt="Profile Picture"
                    class="w-[100px] h-[100px] sm:w-[140px] sm:h-[140px] rounded-full"
                  />
                </div>
                <div class="flex flex-col items-center justify-start">
                  <h2
                    class="text-[30px] text-start sm:text-[50px] text-white font-black font-lalezar sm:ml-10 text-ellipsis whitespace-nowrap overflow-hidden"
                  >
                    {{ displayName }}
                  </h2>
                  <p class="text-sm text-white font-montserrat">
                    <span class="text-[#FCCC67]"> Discord ID:</span> {{ discordID }}
                  </p>
                </div>
              </div>
              <div
                class="z-20 flex flex-col w-full mt-6 space-y-3 text-white font-montserrat"
              >
                <div
                  @click="checkEditPermission"
                  class="flex justify-between items-center hover:cursor-pointer"
                >
                  <span class="font-black text-md sm:text-2xl">Edit Profile</span>
                  <button
                    @click="checkEditPermission"
                    title="You can update your details every 60 days"
                    class="group"
                  >
                    <img
                      src="../assets/edit.webp"
                      class="w-7 h-7 cursor-pointer hover:opacity-80 transition"
                      alt="Edit Icon"
                    />
                  </button>
                </div>
                <div class="flex justify-between">
                  <span class="font-black text-md sm:text-2xl">Stake Username:</span>
                  <span class="overflow-hidden text-ellipsis whitespace-nowrap">{{
                    stake
                  }}</span>
                </div>
                <div class="flex justify-between">
                  <span class="font-black text-md sm:text-2xl">Twitter/ X @:</span>
                  <span class="overflow-hidden text-ellipsis whitespace-nowrap">{{
                    twitter
                  }}</span>
                </div>
                <div class="flex justify-between">
                  <span class="font-black text-md sm:text-2xl">Kick Username:</span>
                  <span class="overflow-hidden text-ellipsis whitespace-nowrap">{{
                    kick
                  }}</span>
                </div>
              </div>
            </div>

            <!-- Disclaimer Card -->
            <div
              class="relative small-box b mt-10 flex flex-col w-[380px] sm:w-[600px] md:w-[670px] h-[390px] sm:h-[270px] md:h-[240px] bg-transparent items-center overflow-hidden"
            >
              <img
                src="@/assets/disclaimer-display-card.webp"
                alt="Card Background"
                class="absolute object-fill w-full h-full rounded-[10px]"
              />
              <div class="z-20 flex flex-col p-10 text-white font-montserrat">
                <h3 class="text-2xl font-black text-center sm:text-start">Disclaimer</h3>
                <ul
                  class="flex flex-col items-start justify-start mt-2 space-y-2 text-xl"
                >
                  <li>
                    1. Users must enter correct information to avoid incorrect tagging and
                    misinformation during sessions.
                  </li>
                  <li>
                    2. The Keithlocks team is not liable for incorrect information entered
                    on the profile page.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <OnboardModal :showModal="showModal" @close="closeModal" @refreshUserData="init" />
    <ProfileLockModal
      :showModal="showDaysLeftModal"
      :daysLeft="daysLeft"
      @close="closeProfileLockModal"
    />
  </section>
</template>

<script>
import OnboardModal from "@/components/OnboardModal.vue";
import ProfileLockModal from "@/components/ProfileLockModal.vue";
import axios from "axios";

export default {
  name: "ProfilePage",
  components: {
    OnboardModal,
    ProfileLockModal,
  },
  data() {
    return {
      isReady: false,
      displayName: null,
      picUrl: null,
      discordID: null,
      twitter: null,
      stake: null,
      kick: null,
      showModal: false,
      showDaysLeftModal: false,
      loading: false,
      daysLeft: 0,
    };
  },
  methods: {
    init() {
      const token = localStorage.getItem("accessToken"); // Retrieve token from localStorage

      if (token) {
        this.$store.commit("UPDATE_USER", { accessToken: token });

        axios
          .get(process.env.VUE_APP_BASE_API + "/api/user", {
            headers: {
              "x-api-key": process.env.VUE_APP_X_API_KEY,
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            if (res.data) {
              this.displayName = res.data.user.username;
              this.picUrl = `https://cdn.discordapp.com/avatars/${res.data.user.discordId}/${res.data.user.profileImage}.png`;
              this.discordID = res.data.user.discordId;
              this.twitter = res.data.user.twitter;
              this.stake = res.data.user.stake;
              this.kick = res.data.user.kick;

              // Compute Days Left
              const lastUpdated = new Date(res.data.user.updatedAt);
              const currentDate = new Date();
              this.daysLeft =
                60 - Math.floor((currentDate - lastUpdated) / (1000 * 60 * 60 * 24));
              if (this.daysLeft < 0) this.daysLeft = 0;

              if (!this.stake || !this.kick) {
            this.showModal = true;
            this.showDaysLeftModal = false; 
          }
            }
          })
          .catch((error) => {
            if (error.response?.status === 401) {
              localStorage.removeItem("vuex");
              localStorage.removeItem("accessToken");
              window.location.reload();
            } else {
              console.log(`Error: ${error.response.status}`);
            }
          });
      }
    },
    checkEditPermission() {
      if (!this.stake || !this.kick) {
    
        this.showModal = true;
        this.showDaysLeftModal = false; 
      } else if (this.daysLeft > 0) {
        
        this.showDaysLeftModal = true;
        this.showModal = false; 
      }
    },
    closeProfileLockModal() {
      this.showDaysLeftModal = false;
    },
    closeModal() {
      this.showModal = false;
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped>
.animated-image {
  animation: growShrink 10s infinite ease-in-out;
}

@keyframes growShrink {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.floating-image1 {
  animation: floatUpDown1 8s infinite ease-in-out, glowEffect1 8s infinite ease-in-out;
}

@keyframes floatUpDown1 {
  0% {
    transform: translateY(20px); /* Start from 20px below */
  }
  50% {
    transform: translateY(0); /* Move to original position */
  }
  100% {
    transform: translateY(20px); /* Move back down */
  }
}

@keyframes glowEffect1 {
  0%,
  100% {
    filter: drop-shadow(0 0 15px rgba(0, 255, 0, 0.5)); /* Glow green at the bottom */
  }
  50% {
    filter: drop-shadow(0 0 30px rgba(0, 255, 0, 0.7)); /* Strong glow when at top */
  }
}

.floating-image2 {
  animation: floatUpDown2 10s infinite ease-in-out, glowEffect2 10s infinite ease-in-out;
}

@keyframes floatUpDown2 {
  0% {
    transform: translateY(20px); /* Start from 20px below */
  }
  50% {
    transform: translateY(0); /* Move to original position */
  }
  100% {
    transform: translateY(20px); /* Move back down */
  }
}
</style>
