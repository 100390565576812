<template>
  <!-- Navbar start -->
  <NavbarElement />
  <!-- Navbar end -->

  <router-view @sections-ready="setupIntersectionObserver"></router-view>

  <!-- Footer start -->
  <FooterElement />
  <!-- Footer end -->
</template>

<script>
import axios from "axios";
import NavbarElement from "@/components/NavbarElement.vue";
import FooterElement from "@/components/FooterElement.vue";
// import LoadingElement from '@/components/LoadingElement.vue';
export default {
  components: {
    FooterElement,
    NavbarElement,
    // LoadingElement
  },
  data() {
    return {
      // activeSection: 'home',
      observer: null,
      // loading: false
    };
  },
  methods: {
    handleLogout() {
      this.loading = true;

      this.$store.commit("UPDATE_USER", null);
      localStorage.removeItem("vuex");
      localStorage.removeItem("accessToken");

      this.$router.replace("/");

      setTimeout(() => {
        this.loading = false;
      }, 2000);
    },
  },

  mounted() {
	console.log("Mounted, checking auth status...");
    const accessToken = this.$route.query.accessToken;
    if (accessToken != null || accessToken != undefined) {
      this.loading = true;
      axios
        .get(process.env.VUE_APP_BASE_API + "/api/user", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((res) => {
          if (res) {
            localStorage.setItem("accessToken", accessToken);
            this.$store.commit("UPDATE_USER", res.data.user);

            setTimeout(() => {
              this.loading = false;
              this.$router.replace("/profile");
            }, 2000);
          }
        });
    }
  },
  // methods: {
  // 	setupIntersectionObserver() {
  // 		this.observer = new IntersectionObserver((entries) => {
  // 			entries.forEach((entry) => {
  // 				if (entry.isIntersecting) {
  // 					this.activeSection = entry.target.id;
  // 				}
  // 			});
  // 		}, { threshold: 0.2 });

  // 		['home', 'socials', 'leaderboards', 'promotions', 'rewards', 'videos'].forEach((id) => {
  // 			const element = document.getElementById(id);
  // 			if (element) this.observer.observe(element);
  // 		});
  // 	}
  // },
  // beforeUnmount() {
  // 	if (this.observer) {
  // 		['home', 'socials', 'leaderboards', 'promotions', 'rewards', 'videos'].forEach((id) => {
  // 			const element = document.getElementById(id);
  // 			if (element) this.observer.unobserve(element);
  // 		});
  // 	}
  // }
};
</script>
