<template>
  <section
    class="min-h-screen h-full overflow-hidden relative bg-gradient-to-t from-[#1F2846] to-[#536091]"
    id="home"
  >
    <vue-particles
      class="absolute top-0 left-0 z-10 object-contain w-full h-full"
      color="#3E5499"
      :particleOpacity="0.7"
      :particlesNumber="30"
      shapeType="circle"
      :particleSize="11"
      linesColor="#dedede"
      :linesWidth="0"
      :lineLinked="false"
      :moveSpeed="3"
      :hoverEffect="false"
      :clickEffect="false"
      :move="{
        direction: 'bottom',
        outMode: 'out',
        random: true,
        speed: 1,
        straight: true,
      }"
    >
    </vue-particles>

    <!-- Content Container -->
    <div
      class="z-30 flex flex-col items-center justify-center w-full px-10 pt-[140px] pb-20 sm:px-14 sm:pt-20 md:pt-20 lg:pt-[70px] 2xl:pt-28 lg:px-40 xl:px-72 xl:pt-[140px] 2xl:px-80"
    >
      <!-- Hero Image (Responsive Display) -->
      <div class="flex flex-col items-center justify-center">
        <!-- Desktop Image -->
        <img
          v-motion-pop-visible
          src="@/assets/hero-keithlocks.webp"
          class="z-20 block w-[300px] lg:w-[420px] mt-10"
          alt="desktop-title-text"
        />
        <div class="relative flex items-center justify-center mt-10">
          <a
            class="relative flex items-center justify-center mt-10 hover:cursor-pointer"
            href="https://stake.com/?c=KeithLocks"
            @mousemove="handleMouseMove"
            @mouseleave="resetTransform"
          >
            <!-- Card -->
            <div
              ref="card"
              class="box b card-container z-30 relative w-[300px] md:w-[400px] h-full bg-[#2C3E67] flex flex-col items-center justify-between text-center overflow-hidden"
            >
              <!-- Card Content -->
              <img
                src="@/assets/hero-card.jpg"
                alt="Card Background"
                class="object-contain w-full h-full rounded-[10px]"
              />
            </div>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent, ref } from "vue";

export default defineComponent({
  methods: {
    // init() {
    //   const urlParams = new URLSearchParams(window.location.search);
    //   const token = urlParams.get('accessToken'); // Extract token from URL
    //   if (token) {
    //     this.$store.commit("UPDATE_USER", { accessToken: token }); // Store in Vuex
    //   }
    //   console.log("Token from URL:", token);
    //   console.log("User state:", this.$store.state.user);
    // }
  },
  mounted() {
    this.$emit("sections-ready");
    // this.init()
  },
  setup() {
    const card = ref(null);

    const handleMouseMove = (event) => {
      const cardElement = card.value;
      const { left, top, width, height } = cardElement.getBoundingClientRect();
      const centerX = left + width / 2;
      const centerY = top + height / 2;

      const rotateX = ((event.clientY - centerY) / height) * 25;
      const rotateY = ((event.clientX - centerX) / width) * -25;

      console.log(rotateX, rotateY);

      cardElement.style.transform = `perspective(1000px) rotateX(${rotateX}deg) rotateY(${rotateY}deg) scale(1.05) `;
    };

    const resetTransform = () => {
      const cardElement = card.value;
      cardElement.style.transform =
        "perspective(1000px) rotateX(0deg) rotateY(0deg) scale(1)";
    };

    return {
      card,
      handleMouseMove,
      resetTransform,
    };
  },
});
</script>

<style scoped>
.card-container {
  transition: transform 0.2s ease-out; /* Smooth transition */
  transform-style: preserve-3d; /* Enable 3D transformations */
}
</style>
